import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableTarifPph21PP68Tahun2009 } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Container, Form, Row, Col } from "react-bootstrap";
import { Box, Pagination, Button, ButtonGroup } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

const TampilTarifPph21PP68Tahun2009 = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeTarifPph21PP68Tahun2009, setKodeTarifPph21PP68Tahun2009] =
    useState("");
  const [jumlahPenghasilanMin, setJumlahPenghasilanMin] = useState("");
  const [jumlahPenghasilanMax, setJumlahPenghasilanMax] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTarifPph21PP68Tahun2009m, setSearchTarifPph21PP68Tahun2009m] =
    useState("");
  const [tarifPph21PP68Tahun2009s, setTarifPph21PP68Tahun2009s] = useState([]);
  const [
    tarifPph21PP68Tahun2009sPagination,
    setTarifPph21PP68Tahun2009sPagination,
  ] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTarifPph21PP68Tahun2009m(query);
  };

  useEffect(() => {
    getTarifPph21PP68Tahun2009sPagination();
    id && getTarifPph21PP68Tahun2009ById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTarifPph21PP68Tahun2009m]);

  const getTarifPph21PP68Tahun2009sPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/tarifPph21PP68Tahun2009sPagination?search_query=${searchTarifPph21PP68Tahun2009m}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setTarifPph21PP68Tahun2009sPagination(
        response.data.tarifPph21PP68Tahun2009s
      );
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTarifPph21PP68Tahun2009s = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/tarifPph21PP68Tahun2009s`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setTarifPph21PP68Tahun2009s(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getTarifPph21PP68Tahun2009ById = async () => {
    if (id) {
      const response = await axios.post(
        `${tempUrl}/tarifPph21PP68Tahun2009s/${id}`,
        {
          _id: user.id,
          token: user.token,
        }
      );
      setKodeTarifPph21PP68Tahun2009(response.data.kodeTarifPph21PP68Tahun2009);
      setJumlahPenghasilanMin(response.data.jumlahPenghasilanMin);
      setJumlahPenghasilanMax(response.data.jumlahPenghasilanMax);
      setTarifPersen(response.data.tarifPersen);
    }
  };

  const deleteTarifPph21PP68Tahun2009 = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteTarifPph21PP68Tahun2009/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchTarifPph21PP68Tahun2009m("");
      setKodeTarifPph21PP68Tahun2009("");
      setJumlahPenghasilanMin("");
      setJumlahPenghasilanMax("");
      setTarifPersen("");
      navigate("/tarifPph21PP68Tahun2009");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(
          `${jumlahPenghasilanMin} tidak bisa dihapus karena sudah ada data!`
        );
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Tarif Pph 21 PP 68 Tahun 2009`, 90, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "TarifPph21PP68Tahun2009",
    sheet: "DaftarTarifPph21PP68Tahun2009",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Tarif Pph 21 PP 68 Tahun 2009</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getTarifPph21PP68Tahun2009s();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getTarifPph21PP68Tahun2009s();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Kode</th>
                <th>Jumlah Penghasilan Min</th>
                <th>Jumlah Penghasilan Max</th>
                <th>Tarif Persen</th>
              </tr>
            </thead>
            <tbody>
              {tarifPph21PP68Tahun2009s.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.kodeTarifPph21PP68Tahun2009}</td>
                  <td>{user.jumlahPenghasilanMin}</td>
                  <td>{user.jumlahPenghasilanMax}</td>
                  <td>{user.tarifPersen}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Kode</th>
                <th>Jumlah Penghasilan Min</th>
                <th>Jumlah Penghasilan Max</th>
                <th>Tarif Persen</th>
              </tr>
              {tarifPph21PP68Tahun2009s.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.kodeTarifPph21PP68Tahun2009}</td>
                  <td>{user.jumlahPenghasilanMin}</td>
                  <td>{user.jumlahPenghasilanMax}</td>
                  <td>{user.tarifPersen}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/tarifPph21PP68Tahun2009/tambahTarifPph21PP68Tahun2009`}
          editLink={`/tarifPph21PP68Tahun2009/${id}/edit`}
          deleteUser={deleteTarifPph21PP68Tahun2009}
          nameUser={kodeTarifPph21PP68Tahun2009}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={kodeTarifPph21PP68Tahun2009}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Min :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jumlahPenghasilanMin.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Max :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={jumlahPenghasilanMax.toLocaleString("de-DE")}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tarif Persen :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={`${tarifPersen} %`}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar
            value={query}
            setSearchTarifPph21PP68Tahun2009m={setQuery}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableTarifPph21PP68Tahun2009
          currentPosts={tarifPph21PP68Tahun2009sPagination}
        />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Container>
  );
};

export default TampilTarifPph21PP68Tahun2009;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};
