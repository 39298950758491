import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const UbahKelompokKegiatanEkonomiKlu = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeSubGolonganKlu, setKodeGolonganPokokKlu] = useState("");
  const [kodeKelompokKegiatanEkonomiKlu, setKodeKelompokKegiatanEkonomiKlu] =
    useState("");
  const [namaKelompokKegiatanEkonomiKlu, setNamaKelompokKegiatanEkonomiKlu] =
    useState("");
  const [ketKelompokKegiatanEkonomiKlu, setKetKelompokKegiatanEkonomiKlu] =
    useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getKelompokKegiatanEkonomiKluById();
  }, []);

  const getKelompokKegiatanEkonomiKluById = async () => {
    setLoading(true);
    const response = await axios.post(
      `${tempUrl}/kelompokKegiatanEkonomiKlus/${id}`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    setKodeGolonganPokokKlu(
      `${response.data.subgolonganklu.kodeSubGolonganKlu} - ${response.data.subgolonganklu.namaSubGolonganKlu}`
    );
    setKodeKelompokKegiatanEkonomiKlu(
      response.data.kodeKelompokKegiatanEkonomiKlu
    );
    setNamaKelompokKegiatanEkonomiKlu(
      response.data.namaKelompokKegiatanEkonomiKlu
    );
    setKetKelompokKegiatanEkonomiKlu(
      response.data.ketKelompokKegiatanEkonomiKlu
    );
    setLoading(false);
  };

  const updateKelompokKegiatanEkonomiKlu = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(
            `${tempUrl}/updateKelompokKegiatanEkonomiKlu/${id}`,
            {
              kodeKelompokKegiatanEkonomiKlu,
              namaKelompokKegiatanEkonomiKlu,
              ketKelompokKegiatanEkonomiKlu,
              userIdUpdate: user.id,
              _id: user.id,
              token: user.token,
              kodeCabang: user.cabang.id,
            }
          );
          setLoading(false);
          navigate(`/kelompokKegiatanEkonomiKlu/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Kelompok Kegiatan Ekonomi KLU</h5>
      <hr />
      <Card>
        <Card.Header>Kelompok Kegiatan Ekonomi KLU</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateKelompokKegiatanEkonomiKlu}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Sub Golongan KLU :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={kodeSubGolonganKlu}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={kodeKelompokKegiatanEkonomiKlu}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaKelompokKegiatanEkonomiKlu}
                      onChange={(e) =>
                        setNamaKelompokKegiatanEkonomiKlu(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketKelompokKegiatanEkonomiKlu}
                      onChange={(e) =>
                        setKetKelompokKegiatanEkonomiKlu(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/kelompokKegiatanEkonomiKlu")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahKelompokKegiatanEkonomiKlu;

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
