import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableJenisObjekPajak } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Colors } from "../../../constants/styles";
import ScreenshotEBupot2126PerhitunganPPhPasal21 from "../../../assets/ScreenshotEBupot2126PerhitunganPPhPasal21.png";
import ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp from "../../../assets/ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp.png";
import ScreenshotEBupot2126TarifTer from "../../../assets/ScreenshotEBupot2126TarifTer.png";
import ScreenshotEBupot2126TarifPkp from "../../../assets/ScreenshotEBupot2126TarifPkp.png";
import ScreenshotEBupot2126TarifFormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126TarifFormulasiPerhitungan.png";
import ScreenshotEBupot2126TarifBebasInput from "../../../assets/ScreenshotEBupot2126TarifBebasInput.png";
import { Container, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const TampilJenisObjekPajak = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [kodeJenisObjekPajak, setKodeJenisObjekPajak] = useState("");
  const [namaJenisObjekPajak, setNamaJenisObjekPajak] = useState("");
  const [bupot2126SkemaPenghitungan, setBupot2126SkemaPenghitungan] =
    useState("");
  const [bupot2126PtkpTahunan, setBupot2126PtkpTahunan] = useState("");
  const [bupot2126DasarPengenaanPajak, setBupot2126DasarPengenaanPajak] =
    useState("");
  const [tarifBupot2126, setTarifBupot2126] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchJenisObjekPajakm, setSearchJenisObjekPajakm] = useState("");
  const [jenisObjekPajaks, setJenisObjekPajaks] = useState([]);
  const [jenisObjekPajaksPagination, setJenisObjekPajaksPagination] = useState(
    []
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchJenisObjekPajakm(query);
  };

  useEffect(() => {
    getJenisObjekPajaksPagination();
    id && getJenisObjekPajakById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchJenisObjekPajakm]);

  const getJenisObjekPajaksPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/jenisObjekPajaksPagination?search_query=${searchJenisObjekPajakm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setJenisObjekPajaksPagination(response.data.jenisObjekPajaks);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJenisObjekPajaks = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/jenisObjekPajaks`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setJenisObjekPajaks(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJenisObjekPajakById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/jenisObjekPajaks/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeObjekPajak(response.data.objekpajak.kodeObjekPajak);
      setKodeJenisObjekPajak(response.data.kodeJenisObjekPajak);
      setNamaJenisObjekPajak(response.data.namaJenisObjekPajak);
      setBupot2126SkemaPenghitungan(response.data.bupot2126SkemaPenghitungan);
      setBupot2126PtkpTahunan(response.data.bupot2126PtkpTahunan);
      setBupot2126DasarPengenaanPajak(
        response.data.bupot2126DasarPengenaanPajak
      );
      setTarifBupot2126(response.data.tarifBupot2126);
    }
  };

  const deleteJenisObjekPajak = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteJenisObjekPajak/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchJenisObjekPajakm("");
      setKodeObjekPajak("");
      setKodeJenisObjekPajak("");
      setNamaJenisObjekPajak("");
      setBupot2126SkemaPenghitungan("");
      setBupot2126PtkpTahunan("");
      setBupot2126DasarPengenaanPajak("");
      setTarifBupot2126("");
      navigate("/jenisObjekPajak");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(
          `${kodeJenisObjekPajak} tidak bisa dihapus karena sudah ada data!`
        );
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Jenis Objek Pajak`, 90, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "JenisObjekPajak",
    sheet: "DaftarJenisObjekPajak",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Jenis Objek Pajak</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJenisObjekPajaks();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getJenisObjekPajaks();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Kode Objek Pajak</th>
                <th>Kode</th>
                <th>Nama PTKP</th>
              </tr>
            </thead>
            <tbody>
              {jenisObjekPajaks.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.objekpajak.kodeObjekPajak}</td>
                  <td>{user.kodeJenisObjekPajak}</td>
                  <td>{user.namaJenisObjekPajak}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Kode Objek Pajak</th>
                <th>Kode</th>
                <th>Nama PTKP</th>
              </tr>
              {jenisObjekPajaks.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.objekpajak.kodeObjekPajak}</td>
                  <td>{user.kodeJenisObjekPajak}</td>
                  <td>{user.namaJenisObjekPajak}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/jenisObjekPajak/tambahJenisObjekPajak`}
          editLink={`/jenisObjekPajak/${id}/edit`}
          deleteUser={deleteJenisObjekPajak}
          nameUser={kodeJenisObjekPajak}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode Objek Pajak :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control value={kodeObjekPajak} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={kodeJenisObjekPajak}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Nama PTKP :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={namaJenisObjekPajak}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Skema Perhitungan di E-Bupot
                                21/26
                              </p>
                              <img
                                src={ScreenshotEBupot2126PerhitunganPPhPasal21}
                                alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Skema
                                  Perhitungan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Skema Perhitungan)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Skema Perhitungan :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={bupot2126SkemaPenghitungan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Ptkp Tahunan di E-Bupot 21/26
                              </p>
                              <img
                                src={ScreenshotEBupot2126PerhitunganPPhPasal21}
                                alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Skema
                                  Perhitungan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Ptkp Tahunan)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Ptkp Tahunan :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={bupot2126PtkpTahunan}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Dasar Pengenaan Pajak di
                                E-Bupot 21/26
                              </p>
                              <img
                                src={
                                  ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp
                                }
                                alt="ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Skema
                                  Perhitungan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Dasar Pengenaan Pajak)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Dasar Pengenaan Pajak :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={bupot2126DasarPengenaanPajak}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk Tarif E-Bupot 21/26</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotEBupot2126TarifTer}
                                  alt="ScreenshotEBupot2126TarifTer"
                                  style={{ width: "500px" }}
                                />
                                <li>TER</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif TER)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotEBupot2126TarifPkp}
                                  alt="ScreenshotEBupot2126TarifPkp"
                                  style={{ width: "500px" }}
                                />
                                <li>PKP</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif PKP)
                                </p>
                                <hr />
                                <img
                                  src={
                                    ScreenshotEBupot2126TarifFormulasiPerhitungan
                                  }
                                  alt="ScreenshotEBupot2126TarifFormulasiPerhitungan"
                                  style={{ width: "500px" }}
                                />
                                <li>Formulasi Penghitungan</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif Formulasi Penghitungan)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotEBupot2126TarifBebasInput}
                                  alt="ScreenshotEBupot2126TarifBebasInput"
                                  style={{ width: "500px" }}
                                />
                                <li>Bebas Input</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif Bebas Input)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Tarif Bupot 21/26 :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control value={tarifBupot2126} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchJenisObjekPajakm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJenisObjekPajak currentPosts={jenisObjekPajaksPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TampilJenisObjekPajak;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
