import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableObjekPajak } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifier } from "../../../components";
import { Colors } from "../../../constants/styles";
import ScreenshotPralapor from "../../../assets/ScreenshotPralapor.png";
import ScreenshotEBupotUnifikasiPPh from "../../../assets/ScreenshotEBupotUnifikasiPPh.png";
import ScreenshotEBupotUnifikasiKirimSptLampiranDopp from "../../../assets/ScreenshotEBupotUnifikasiKirimSptLampiranDopp.png";
import ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden from "../../../assets/ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden.png";
import ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden from "../../../assets/ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden.png";
import ScreenshotEBupot2126PerhitunganPPhPasal21 from "../../../assets/ScreenshotEBupot2126PerhitunganPPhPasal21.png";
import ScreenshotEBupot2126 from "../../../assets/ScreenshotEBupot2126.png";
import ScreenshotEBupot2126PPhPasal21JenisObjekPajak from "../../../assets/ScreenshotEBupot2126PPhPasal21JenisObjekPajak.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto.png";
import ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan.png";
import ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn from "../../../assets/ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn.png";
import ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln from "../../../assets/ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln.png";
import ScreenshotEBupot2126TarifTer from "../../../assets/ScreenshotEBupot2126TarifTer.png";
import ScreenshotEBupot2126TarifPkp from "../../../assets/ScreenshotEBupot2126TarifPkp.png";
import ScreenshotEBupot2126TarifFormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126TarifFormulasiPerhitungan.png";
import ScreenshotEBupot2126TarifBebasInput from "../../../assets/ScreenshotEBupot2126TarifBebasInput.png";
import ScreenshotEBupot2126TarifDitentukanJenisObjekPajak from "../../../assets/ScreenshotEBupot2126TarifDitentukanJenisObjekPajak.png";
import ScreenshotEBupot2126FixTarifPersen from "../../../assets/ScreenshotEBupot2126FixTarifPersen.png";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Pagination,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDownloadExcel } from "react-export-table-to-excel";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const TampilObjekPajak = () => {
  const ref = useRef(null);
  const tableRef = useRef(null);
  const { user, dispatch, setting } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();

  const [isFetchError, setIsFetchError] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [namaObjekPajak, setNamaObjekPajak] = useState("");
  const [ketObjekPajak, setKetObjekPajak] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");
  const [isBupotUnifikasi, setIsBupotUnifikasi] = useState("");
  const [untukBupotUnifikasi, setUntukBupotUnifikasi] = useState("");
  const [bupotUnifikasiDopp, setBupotUnifikasiDopp] = useState("");
  const [
    pilihanPerkiraanPenghasilanNetto,
    setPilihanPerkiraanPenghasilanNetto,
  ] = useState("");
  const [perkiraanPenghasilanNetto, setPerkiraanPenghasilanNetto] =
    useState("");
  const [isBupot2126, setIsBupot2126] = useState("");
  const [isActiveBupot2126, setIsActiveBupot2126] = useState("");
  const [untukBupot2126, setUntukBupot2126] = useState("");
  const [bupot2126SkemaPenghitungan, setBupot2126SkemaPenghitungan] =
    useState("");
  const [bupot2126PtkpTahunan, setBupot2126PtkpTahunan] = useState("");
  const [bupot2126JenisObjekPajak, setBupot2126JenisObjekPajak] = useState("");
  const [bupot2126DasarPengenaanPajak, setBupot2126DasarPengenaanPajak] =
    useState("");
  const [
    bupot2126DasarPengenaanPajakBebasInput,
    setBupot2126DasarPengenaanPajakBebasInput,
  ] = useState("");
  const [
    bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto,
    setBupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto,
  ] = useState("");
  const [bupot2126FormulasiPenghitungan, setBupot2126FormulasiPenghitungan] =
    useState("");
  const [bupot2126FasilitasDtpIkn, setBupot2126FasilitasDtpIkn] = useState("");
  const [bupot2126FasilitasSkdWpln, setBupot2126FasilitasSkdWpln] =
    useState("");
  const [tarifBupot2126, setTarifBupot2126] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewExcel, setPreviewExcel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [objekPajaks, setObjekPajaks] = useState([]);
  const [objekPajaksPagination, setObjekPajaksPagination] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  useEffect(() => {
    getObjekPajaksPagination();
    id && getObjekPajakById();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [id, page, searchTerm]);

  const getObjekPajaksPagination = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${tempUrl}/objekPajaksPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
        {
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        }
      );
      setObjekPajaksPagination(response.data.objekPajaks);
      setPage(response.data.page);
      setPages(response.data.totalPage);
      setRows(response.data.totalRows);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getObjekPajaks = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${tempUrl}/objekPajaks`, {
        _id: user.id,
        token: user.token,
        kodeCabang: user.cabang.id,
      });
      setObjekPajaks(response.data);
    } catch (error) {
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getObjekPajakById = async () => {
    if (id) {
      const response = await axios.post(`${tempUrl}/objekPajaks/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setKodeJenisPajak(
        `${response.data.jenissetoran.jenispajak.kodeJenisPajak} - ${response.data.jenissetoran.jenispajak.namaJenisPajak}`
      );
      setKodeJenisSetoran(
        `${response.data.jenissetoran.kodeJenisSetoran} - ${response.data.jenissetoran.namaJenisSetoran}`
      );
      setKodeObjekPajak(response.data.kodeObjekPajak);
      setNamaObjekPajak(response.data.namaObjekPajak);
      setKetObjekPajak(response.data.ketObjekPajak);
      setTarifPersen(response.data.tarifPersen);
      setIsBupotUnifikasi(response.data.isBupotUnifikasi);
      setUntukBupotUnifikasi(response.data.untukBupotUnifikasi);
      setBupotUnifikasiDopp(response.data.bupotUnifikasiDopp);
      setPilihanPerkiraanPenghasilanNetto(
        response.data.pilihanPerkiraanPenghasilanNetto
      );
      setPerkiraanPenghasilanNetto(response.data.perkiraanPenghasilanNetto);
      setIsBupot2126(response.data.isBupot2126);
      setIsActiveBupot2126(response.data.isActiveBupot2126);
      setUntukBupot2126(response.data.untukBupot2126);
      setBupot2126SkemaPenghitungan(response.data.bupot2126SkemaPenghitungan);
      setBupot2126PtkpTahunan(response.data.bupot2126PtkpTahunan);
      setBupot2126JenisObjekPajak(response.data.bupot2126JenisObjekPajak);
      setBupot2126DasarPengenaanPajak(
        response.data.bupot2126DasarPengenaanPajak
      );
      setBupot2126DasarPengenaanPajakBebasInput(
        response.data.bupot2126DasarPengenaanPajakBebasInput
      );
      setBupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto(
        response.data.bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto
      );
      setBupot2126FormulasiPenghitungan(
        response.data.bupot2126FormulasiPenghitungan
      );
      setBupot2126FasilitasDtpIkn(response.data.bupot2126FasilitasDtpIkn);
      setBupot2126FasilitasSkdWpln(response.data.bupot2126FasilitasSkdWpln);
      setTarifBupot2126(response.data.tarifBupot2126);
    }
  };

  const deleteObjekPajak = async (id) => {
    setLoading(true);
    try {
      await axios.post(`${tempUrl}/deleteObjekPajak/${id}`, {
        _id: user.id,
        token: user.token,
      });
      setSearchTerm("");
      setKodeJenisPajak("");
      setKodeJenisSetoran("");
      setNamaObjekPajak("");
      setKodeObjekPajak("");
      setKetObjekPajak("");
      setTarifPersen("");
      setIsBupotUnifikasi("");
      setUntukBupotUnifikasi("");
      setBupotUnifikasiDopp("");
      setPilihanPerkiraanPenghasilanNetto("");
      setPerkiraanPenghasilanNetto("");
      setIsBupot2126("");
      setIsActiveBupot2126("");
      setUntukBupot2126("");
      setBupot2126SkemaPenghitungan("");
      setBupot2126PtkpTahunan("");
      setBupot2126JenisObjekPajak("");
      setBupot2126DasarPengenaanPajak("");
      setBupot2126DasarPengenaanPajakBebasInput("");
      setBupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto("");
      setBupot2126FormulasiPenghitungan("");
      setBupot2126FasilitasDtpIkn("");
      setBupot2126FasilitasSkdWpln("");
      setTarifBupot2126("");
      navigate("/objekPajak");
    } catch (error) {
      if (error.response.data.message.includes("foreign key")) {
        alert(`${namaObjekPajak} tidak bisa dihapus karena sudah ada data!`);
      }
    }
    setLoading(false);
  };

  const downloadPdf = () => {
    var date = new Date();
    var current_date = formatDate(date);
    var current_time =
      date.getHours().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getMinutes().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }) +
      ":" +
      date.getSeconds().toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(
      `${setting.namaPerusahaan} - ${setting.kabupatenPerusahaan}`,
      15,
      10
    );
    doc.text(`${setting.alamatPerusahaan}`, 15, 15);
    doc.setFontSize(14);
    doc.text(`Daftar Objek Pajak`, 90, 30);
    doc.setFontSize(8);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.autoTable({
      html: "#table",
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
      styles: {
        fontSize: 8,
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "ObjekPajak",
    sheet: "DaftarObjekPajak",
  });

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Container ref={ref}>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Daftar Objek Pajak</h5>
      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getObjekPajaks();
              setPreviewPdf(!previewPdf);
              setPreviewExcel(false);
            }}
          >
            PDF
          </Button>
          <Button
            color="secondary"
            startIcon={<SearchIcon />}
            onClick={() => {
              getObjekPajaks();
              setPreviewExcel(!previewExcel);
              setPreviewPdf(false);
            }}
          >
            Excel
          </Button>
        </ButtonGroup>
      </Box>
      {previewPdf && (
        <div>
          <Button
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={() => downloadPdf()}
          >
            CETAK
          </Button>
          <table class="table" id="table">
            <thead>
              <tr>
                <th>Jenis Pajak</th>
                <th>Jenis Setoran</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Ket</th>
                <th>Tarif</th>
              </tr>
            </thead>
            <tbody>
              {objekPajaks.map((user, index) => (
                <tr key={user.id}>
                  <td>{`${user.jenissetoran.jenispajak.kodeJenisPajak} - ${user.jenissetoran.jenispajak.namaJenisPajak}`}</td>
                  <td>{`${user.jenissetoran.kodeJenisSetoran} - ${user.jenissetoran.namaJenisSetoran}`}</td>
                  <td>{user.kodeObjekPajak}</td>
                  <td>{user.namaObjekPajak}</td>
                  <td>{user.ketObjekPajak}</td>
                  <td>{user.tarifPersen}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div>
        {previewExcel && (
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={onDownload}
          >
            EXCEL
          </Button>
        )}
        <table ref={tableRef}>
          {previewExcel && (
            <tbody>
              <tr>
                <th>Jenis Pajak</th>
                <th>Jenis Setoran</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Ket</th>
                <th>Tarif</th>
              </tr>
              {objekPajaks.map((user, index) => (
                <tr key={user.id}>
                  <td>{`${user.jenissetoran.jenispajak.kodeJenisPajak} - ${user.jenissetoran.jenispajak.namaJenisPajak}`}</td>
                  <td>{`${user.jenissetoran.kodeJenisSetoran} - ${user.jenissetoran.namaJenisSetoran}`}</td>
                  <td>{user.kodeObjekPajak}</td>
                  <td>{user.namaObjekPajak}</td>
                  <td>{user.ketObjekPajak}</td>
                  <td>{user.tarifPersen}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <Box sx={buttonModifierContainer}>
        <ButtonModifier
          id={id}
          kode={id}
          addLink={`/objekPajak/tambahObjekPajak`}
          editLink={`/objekPajak/${id}/edit`}
          deleteUser={deleteObjekPajak}
          nameUser={kodeObjekPajak}
        />
      </Box>
      {id && (
        <Container>
          <hr />
          <Form>
            <Card>
              <Card.Header>Objek Pajak</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Kode Jenis Pajak :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={kodeJenisPajak}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Kode Jenis Setoran :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={kodeJenisSetoran}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Kode :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          value={kodeObjekPajak}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Nama :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={namaObjekPajak}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Keterangan :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={ketObjekPajak}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRight}>
                        Tarif :
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          value={`${tarifPersen} %`}
                          disabled
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* EBupot Unifikasi */}
                <Card>
                  <Card.Header>EBupot Unifikasi</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot Unifikasi
                                      </p>
                                      <img
                                        src={ScreenshotPralapor}
                                        alt="ScreenshotPralapor"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini tidak untuk
                                          E-Bupot Unifikasi)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Kategori Bupot Unifikasi :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={isBupotUnifikasi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot Unifikasi berdasarkan pasal
                                        masing-masing
                                      </p>
                                      <img
                                        src={ScreenshotEBupotUnifikasiPPh}
                                        alt="ScreenshotEBupotUnifikasiPPh"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>PPh 42152223</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi PPh Pasal 4(2), 15,
                                          22, 23)
                                        </p>
                                        <li>PPh Non Residen</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi PPh Non Residen)
                                        </p>
                                        <li>PPh DOSS</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi PPh DOSS)
                                        </p>
                                      </ul>
                                      <p>
                                        <b>
                                          Jika bukan merupakan Kode Objek Pajak
                                          untuk E-Bupot Unifikasi, maka jangan
                                          diisi
                                        </b>
                                      </p>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Untuk Bupot Unifikasi :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={untukBupotUnifikasi}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot Unifikasi untuk PPh DOPP yang
                                        muncul saat Penyiapan Kirim SPT
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupotUnifikasiKirimSptLampiranDopp
                                        }
                                        alt="ScreenshotEBupotUnifikasiKirimSptLampiranDopp"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi untuk PPh DOPP yang
                                          muncul saat Penyiapan Kirim SPT)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini tidak untuk
                                          E-Bupot Unifikasi untuk PPh DOPP yang
                                          muncul saat Penyiapan Kirim SPT)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Bupot Unifikasi Dopp :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupotUnifikasiDopp}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot Unifikasi untuk PPh yang
                                        memiliki Pilihan Perkiraan Penghasilan
                                      </p>
                                      <img
                                        src={
                                          ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden
                                        }
                                        alt="ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>1</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi untuk PPh yang
                                          memiliki Pilihan Perkiraan
                                          Penghasilan)
                                        </p>
                                        <li>0</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot Unifikasi untuk PPh yang tidak
                                          memiliki Pilihan Perkiraan
                                          Penghasilan)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Pilihan Perkiraan Penghasilan Netto :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={pilihanPerkiraanPenghasilanNetto}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengatur Perkiraan Penghasilan
                                        Netto Kode Objek Pajak di E-Bupot
                                        Unifikasi untuk PPh Non Residen
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden
                                        }
                                        alt="ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Diinput dalam satuan persen: 0-100%</p>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Perkiraan Penghasilan Netto :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={`${perkiraanPenghasilanNetto} %`}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* EBupot 21/26 */}
                <Card style={{ marginTop: "20px" }}>
                  <Card.Header>EBupot 21/26</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot 21/26
                                      </p>
                                      <img
                                        src={ScreenshotPralapor}
                                        alt="ScreenshotPralapor"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot 21/26)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini tidak untuk
                                          E-Bupot 21/26)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Kategori Bupot 21/26 :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={isBupot2126}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        inputan E-Bupot 21/26
                                      </p>
                                      <img
                                        src={ScreenshotPralapor}
                                        alt="ScreenshotPralapor"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini masih aktif
                                          di inputan E-Bupot 21/26)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini tidak lagi
                                          aktif di inputan E-Bupot 21/26)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Kategori Bupot 21/26 Active :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={isActiveBupot2126}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Kode Objek Pajak di
                                        E-Bupot 21/26 berdasarkan pasal
                                        masing-masing
                                      </p>
                                      <img
                                        src={ScreenshotEBupot2126}
                                        alt="ScreenshotEBupot2126"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>PPh 21</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot 21/26 PPh Pasal 21)
                                        </p>
                                        <li>PPh 26</li>
                                        <p>
                                          (Jika Kode Objek Pajak ini untuk
                                          E-Bupot 21/26 PPh Pasal 26)
                                        </p>
                                      </ul>
                                      <p>
                                        <b>
                                          Jika bukan merupakan Kode Objek Pajak
                                          untuk E-Bupot 21/26, maka jangan diisi
                                        </b>
                                      </p>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Untuk Bupot 21/26 :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={untukBupot2126}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Skema Perhitungan di
                                        E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PerhitunganPPhPasal21
                                        }
                                        alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Skema Perhitungan)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Skema Perhitungan)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Skema Perhitungan :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126SkemaPenghitungan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Ptkp Tahunan di
                                        E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PerhitunganPPhPasal21
                                        }
                                        alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Ptkp Tahunan)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Ptkp Tahunan)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Ptkp Tahunan :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126PtkpTahunan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Jenis Objek Pajak di
                                        E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21JenisObjekPajak
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21JenisObjekPajak"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Jenis Objek Pajak)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Jenis Objek Pajak)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Jenis Objek Pajak :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126JenisObjekPajak}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Dasar Pengenaan Pajak
                                        di E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Dasar Pengenaan Pajak)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Dasar Pengenaan Pajak)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Dasar Pengenaan Pajak :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126DasarPengenaanPajak}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Dasar Pengenaan Pajak
                                        Bebas Input di E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Dasar Pengenaan Pajak Bebas Input)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Dasar Pengenaan Pajak Bebas
                                          Input)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Dasar Pengenaan Pajak Bebas Input :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126DasarPengenaanPajakBebasInput}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan DPP Akumulasi
                                        Penghasilan Bruto Bebas Input di E-Bupot
                                        21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada DPP Akumulasi Penghasilan Bruto
                                          Bebas Input)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada DPP Akumulasi Penghasilan
                                          Bruto Bebas Input)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            DPP Akumulasi Penghasilan Bruto :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={
                                bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto
                              }
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Formulasi
                                        Penghitungan Bebas Input di E-Bupot
                                        21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Formulasi Penghitungan Bebas
                                          Input)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Formulasi Penghitungan Bebas
                                          Input)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Formulasi Penghitungan :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126FormulasiPenghitungan}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Fasilitas DTP IKN
                                        Input di E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Fasilitas DTP IKN Input)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Fasilitas DTP IKN Input)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Fasilitas DTP IKN :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126FasilitasDtpIkn}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Fasilitas SKD WPLN
                                        Input di E-Bupot 21/26
                                      </p>
                                      <img
                                        src={
                                          ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln
                                        }
                                        alt="ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln"
                                        style={{ width: "550px" }}
                                      />
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>true</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          ada Fasilitas SKD WPLN Input)
                                        </p>
                                        <li>false</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          tidak ada Fasilitas SKD WPLN Input)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Fasilitas SKD WPLN :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={bupot2126FasilitasSkdWpln}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="6" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>Untuk Tarif E-Bupot 21/26</p>
                                      <p>Pilihan:</p>
                                      <ul>
                                        <img
                                          src={ScreenshotEBupot2126TarifTer}
                                          alt="ScreenshotEBupot2126TarifTer"
                                          style={{ width: "500px" }}
                                        />
                                        <li>TER</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan tarif TER)
                                        </p>
                                        <hr />
                                        <img
                                          src={ScreenshotEBupot2126TarifPkp}
                                          alt="ScreenshotEBupot2126TarifPkp"
                                          style={{ width: "500px" }}
                                        />
                                        <li>PKP</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan tarif PKP)
                                        </p>
                                        <hr />
                                        <img
                                          src={
                                            ScreenshotEBupot2126TarifFormulasiPerhitungan
                                          }
                                          alt="ScreenshotEBupot2126TarifFormulasiPerhitungan"
                                          style={{ width: "500px" }}
                                        />
                                        <li>Formulasi Penghitungan</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan tarif Formulasi
                                          Penghitungan)
                                        </p>
                                        <hr />
                                        <img
                                          src={
                                            ScreenshotEBupot2126TarifBebasInput
                                          }
                                          alt="ScreenshotEBupot2126TarifBebasInput"
                                          style={{ width: "500px" }}
                                        />
                                        <li>Bebas Input</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan tarif Bebas Input)
                                        </p>
                                        <hr />
                                        <img
                                          src={
                                            ScreenshotEBupot2126TarifDitentukanJenisObjekPajak
                                          }
                                          alt="ScreenshotEBupot2126TarifDitentukanJenisObjekPajak"
                                          style={{ width: "500px" }}
                                        />
                                        <li>Ditentukan Jenis Objek Pajak</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan tarif Ditentukan Jenis
                                          Objek Pajak)
                                        </p>
                                        <hr />
                                        <img
                                          src={
                                            ScreenshotEBupot2126FixTarifPersen
                                          }
                                          alt="ScreenshotEBupot2126FixTarifPersen"
                                          style={{ width: "500px" }}
                                        />
                                        <li>Fix Tarif Persen</li>
                                        <p>
                                          (Jika Kode Objek Pajak E-Bupot 21/26
                                          menggunakan Fix Tarif Persen dari
                                          Master)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Tarif Bupot 21/26 :
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              value={tarifBupot2126}
                              disabled
                              readOnly
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Form>
        </Container>
      )}
      <hr />
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableObjekPajak currentPosts={objekPajaksPagination} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TampilObjekPajak;

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const spacingTop = {
  marginTop: "20px",
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
