import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import ScreenshotPralapor from "../../../assets/ScreenshotPralapor.png";
import ScreenshotEBupotUnifikasiPPh from "../../../assets/ScreenshotEBupotUnifikasiPPh.png";
import ScreenshotEBupotUnifikasiKirimSptLampiranDopp from "../../../assets/ScreenshotEBupotUnifikasiKirimSptLampiranDopp.png";
import ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden from "../../../assets/ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden.png";
import ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden from "../../../assets/ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden.png";
import ScreenshotEBupot2126PerhitunganPPhPasal21 from "../../../assets/ScreenshotEBupot2126PerhitunganPPhPasal21.png";
import ScreenshotEBupot2126 from "../../../assets/ScreenshotEBupot2126.png";
import ScreenshotEBupot2126PPhPasal21JenisObjekPajak from "../../../assets/ScreenshotEBupot2126PPhPasal21JenisObjekPajak.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput.png";
import ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto from "../../../assets/ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto.png";
import ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan.png";
import ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn from "../../../assets/ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn.png";
import ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln from "../../../assets/ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln.png";
import ScreenshotEBupot2126TarifTer from "../../../assets/ScreenshotEBupot2126TarifTer.png";
import ScreenshotEBupot2126TarifPkp from "../../../assets/ScreenshotEBupot2126TarifPkp.png";
import ScreenshotEBupot2126TarifFormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126TarifFormulasiPerhitungan.png";
import ScreenshotEBupot2126TarifBebasInput from "../../../assets/ScreenshotEBupot2126TarifBebasInput.png";
import ScreenshotEBupot2126TarifDitentukanJenisObjekPajak from "../../../assets/ScreenshotEBupot2126TarifDitentukanJenisObjekPajak.png";
import ScreenshotEBupot2126FixTarifPersen from "../../../assets/ScreenshotEBupot2126FixTarifPersen.png";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const TambahObjekPajak = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [namaObjekPajak, setNamaObjekPajak] = useState("");
  const [ketObjekPajak, setKetObjekPajak] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  let isBupotUnifikasiOptions = [{ label: "true" }, { label: "false" }];
  const [isBupotUnifikasi, setIsBupotUnifikasi] = useState("");

  let untukBupotUnifikasiOptions = [
    { label: "PPh 42152223" },
    { label: "PPh Non Residen" },
    { label: "PPh DOSS" },
  ];
  const [untukBupotUnifikasi, setUntukBupotUnifikasi] = useState("");

  let bupotUnifikasiDoppOptions = [{ label: "true" }, { label: "false" }];
  const [bupotUnifikasiDopp, setBupotUnifikasiDopp] = useState("");

  let pilihanPerkiraanPenghasilanNettoOptions = [
    { label: "0" },
    { label: "1" },
  ];
  const [
    pilihanPerkiraanPenghasilanNetto,
    setPilihanPerkiraanPenghasilanNetto,
  ] = useState("");

  const [perkiraanPenghasilanNetto, setPerkiraanPenghasilanNetto] =
    useState("");

  let isBupot2126Options = [{ label: "true" }, { label: "false" }];
  const [isBupot2126, setIsBupot2126] = useState("");

  let isActiveBupot2126Options = [{ label: "true" }, { label: "false" }];
  const [isActiveBupot2126, setIsActiveBupot2126] = useState("");

  let untukBupot2126Options = [{ label: "PPh 21" }, { label: "PPh 26" }];
  const [untukBupot2126, setUntukBupot2126] = useState("");

  let bupot2126SkemaPenghitunganOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126SkemaPenghitungan, setBupot2126SkemaPenghitungan] =
    useState("");

  let bupot2126PtkpTahunanOptions = [{ label: "true" }, { label: "false" }];
  const [bupot2126PtkpTahunan, setBupot2126PtkpTahunan] = useState("");

  let bupot2126JenisObjekPajakOptions = [{ label: "true" }, { label: "false" }];
  const [bupot2126JenisObjekPajak, setBupot2126JenisObjekPajak] = useState("");

  let bupot2126DasarPengenaanPajakOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126DasarPengenaanPajak, setBupot2126DasarPengenaanPajak] =
    useState("");

  let bupot2126DasarPengenaanPajakBebasInputOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [
    bupot2126DasarPengenaanPajakBebasInput,
    setBupot2126DasarPengenaanPajakBebasInput,
  ] = useState("");

  let bupot2126DasarPengenaanPajakAkumulasiPenghasilanBrutoOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [
    bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto,
    setBupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto,
  ] = useState("");

  let bupot2126FormulasiPenghitunganOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126FormulasiPenghitungan, setBupot2126FormulasiPenghitungan] =
    useState("");

  let bupot2126FasilitasDtpIknOptions = [{ label: "true" }, { label: "false" }];
  const [bupot2126FasilitasDtpIkn, setBupot2126FasilitasDtpIkn] = useState("");

  let bupot2126FasilitasSkdWplnOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126FasilitasSkdWpln, setBupot2126FasilitasSkdWpln] =
    useState("");

  let tarifBupot2126Options = [
    { label: "TER" },
    { label: "PKP" },
    { label: "Formulasi Penghitungan" },
    { label: "Bebas Input" },
    { label: "Ditentukan Jenis Objek Pajak" },
    { label: "Fix Tarif Persen" },
  ];
  const [tarifBupot2126, setTarifBupot2126] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [jenisPajaks, setJenisPajaks] = useState([]);
  const [jenisSetorans, setJenisSetorans] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let jenisPajakOptions = jenisPajaks.map((jenisPajak) => ({
    label: `${jenisPajak.kodeJenisPajak} - ${jenisPajak.namaJenisPajak}`,
  }));

  let jenisSetoranOptions = jenisSetorans.map((jenisSetoran) => ({
    label: `${jenisSetoran.kodeJenisSetoran} - ${jenisSetoran.namaJenisSetoran}`,
  }));

  useEffect(() => {
    getJenisPajakData();
  }, []);

  const getJenisPajakData = async () => {
    const response = await axios.post(`${tempUrl}/jenisPajaks`, {
      _id: user.id,
      token: user.token,
    });
    setJenisPajaks(response.data);
  };

  const getJenisSetoranData = async (kodeJenisPajak) => {
    const response = await axios.post(
      `${tempUrl}/jenisSetoransByKodeJenisPajak`,
      {
        kodeJenisPajak,
        _id: user.id,
        token: user.token,
      }
    );
    setJenisSetorans(response.data);
  };

  const findObjekPajakNextKode = async (kodeJenisPajak, kodeJenisSetoran) => {
    const response = await axios.post(`${tempUrl}/objekPajakNextKode`, {
      kodeJenisPajak,
      kodeJenisSetoran: kodeJenisSetoran.split(" ", 1)[0],
      _id: user.id,
      token: user.token,
    });
    if (response.data) {
      setKodeObjekPajak(response.data);
    } else {
      setKodeObjekPajak("");
    }
  };

  const saveObjekPajak = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeJenisSetoran.length !== 0 &&
      isBupotUnifikasi.length !== 0 &&
      bupotUnifikasiDopp.length !== 0 &&
      pilihanPerkiraanPenghasilanNetto.length !== 0 &&
      perkiraanPenghasilanNetto.length !== 0 &&
      isBupot2126.length !== 0 &&
      isActiveBupot2126.length !== 0 &&
      untukBupot2126.length !== 0 &&
      bupot2126SkemaPenghitungan.length !== 0 &&
      bupot2126PtkpTahunan.length !== 0 &&
      bupot2126JenisObjekPajak.length !== 0 &&
      bupot2126DasarPengenaanPajak.length !== 0 &&
      bupot2126DasarPengenaanPajakBebasInput.length !== 0 &&
      bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto.length !== 0 &&
      bupot2126FormulasiPenghitungan.length !== 0 &&
      bupot2126FasilitasDtpIkn.length !== 0 &&
      bupot2126FasilitasSkdWpln.length !== 0 &&
      tarifBupot2126.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveObjekPajak`, {
          kodeJenisPajak,
          kodeJenisSetoran: kodeJenisSetoran.split(" ", 1)[0],

          kodeObjekPajak,
          namaObjekPajak,
          ketObjekPajak,
          tarifPersen,
          isBupotUnifikasi,
          untukBupotUnifikasi,
          bupotUnifikasiDopp,
          pilihanPerkiraanPenghasilanNetto,
          perkiraanPenghasilanNetto,

          isBupot2126,
          isActiveBupot2126,
          untukBupot2126,
          bupot2126SkemaPenghitungan,
          bupot2126PtkpTahunan,
          bupot2126JenisObjekPajak,
          bupot2126DasarPengenaanPajak,
          bupot2126DasarPengenaanPajakBebasInput,
          bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto,
          bupot2126FormulasiPenghitungan,
          bupot2126FasilitasDtpIkn,
          bupot2126FasilitasSkdWpln,
          tarifBupot2126,

          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/objekPajak");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Objek Pajak</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={saveObjekPajak}>
        <Card>
          <Card.Header>Objek Pajak</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode Jenis Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisPajak.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisPajak(value.split(" ", 1)[0]);

                        if (value.length === 0) {
                          setKodeJenisSetoran("");
                          setJenisSetorans([]);
                        } else {
                          getJenisSetoranData(value.split(" ", 1)[0]);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode Jenis Setoran <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisSetoranOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisSetoran.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisSetoran(value);

                        if (value.length === 0) {
                          setKodeObjekPajak("");
                        } else {
                          findObjekPajakNextKode(kodeJenisPajak, value);
                        }
                      }}
                      value={kodeJenisSetoran}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      required
                      value={kodeObjekPajak}
                      onChange={(e) => setKodeObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaObjekPajak}
                      onChange={(e) => setNamaObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketObjekPajak}
                      onChange={(e) => setKetObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Tarif (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      required
                      type="number"
                      value={tarifPersen}
                      onChange={(e) => setTarifPersen(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            {/* EBupot Unifikasi */}
            <Card>
              <Card.Header>EBupot Unifikasi</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot Unifikasi
                                  </p>
                                  <img
                                    src={ScreenshotPralapor}
                                    alt="ScreenshotPralapor"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini tidak untuk
                                      E-Bupot Unifikasi)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Kategori Bupot Unifikasi <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={isBupotUnifikasiOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && isBupotUnifikasi.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setIsBupotUnifikasi(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot Unifikasi berdasarkan pasal
                                    masing-masing
                                  </p>
                                  <img
                                    src={ScreenshotEBupotUnifikasiPPh}
                                    alt="ScreenshotEBupotUnifikasiPPh"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>PPh 42152223</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi PPh Pasal 4(2), 15, 22, 23)
                                    </p>
                                    <li>PPh Non Residen</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi PPh Non Residen)
                                    </p>
                                    <li>PPh DOSS</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi PPh DOSS)
                                    </p>
                                  </ul>
                                  <p>
                                    <b>
                                      Jika bukan merupakan Kode Objek Pajak
                                      untuk E-Bupot Unifikasi, maka jangan diisi
                                    </b>
                                  </p>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Untuk Bupot Unifikasi :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={untukBupotUnifikasiOptions}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          onInputChange={(e, value) => {
                            setUntukBupotUnifikasi(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot Unifikasi untuk PPh DOPP yang muncul
                                    saat Penyiapan Kirim SPT
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupotUnifikasiKirimSptLampiranDopp
                                    }
                                    alt="ScreenshotEBupotUnifikasiKirimSptLampiranDopp"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi untuk PPh DOPP yang muncul saat
                                      Penyiapan Kirim SPT)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini tidak untuk
                                      E-Bupot Unifikasi untuk PPh DOPP yang
                                      muncul saat Penyiapan Kirim SPT)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Bupot Unifikasi Dopp <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupotUnifikasiDoppOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && bupotUnifikasiDopp.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupotUnifikasiDopp(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot Unifikasi untuk PPh yang memiliki
                                    Pilihan Perkiraan Penghasilan
                                  </p>
                                  <img
                                    src={
                                      ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden
                                    }
                                    alt="ScreenshotPilihanPerkiraanResikoEBupotUnifikasiPphNonResiden"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>1</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi untuk PPh yang memiliki Pilihan
                                      Perkiraan Penghasilan)
                                    </p>
                                    <li>0</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      Unifikasi untuk PPh yang tidak memiliki
                                      Pilihan Perkiraan Penghasilan)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Pilihan Perkiraan Penghasilan Netto{" "}
                        <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={pilihanPerkiraanPenghasilanNettoOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                pilihanPerkiraanPenghasilanNetto.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setPilihanPerkiraanPenghasilanNetto(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengatur Perkiraan Penghasilan Netto
                                    Kode Objek Pajak di E-Bupot Unifikasi untuk
                                    PPh Non Residen
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden
                                    }
                                    alt="ScreenshotEBupotUnifikasiPerkiraanPenghasilanNettoPPhNonResiden"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Diinput dalam satuan persen: 0-100%</p>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Perkiraan Penghasilan Netto (%)
                        <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <NumericFormat
                          required
                          value={perkiraanPenghasilanNetto}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          customInput={Form.Control}
                          onValueChange={(values) => {
                            let numericValue = parseFloat(
                              values.formattedValue.replace(/[\.,]/g, "")
                            );

                            // Limit the value to the range 0-100
                            if (numericValue > 100) {
                              numericValue = 100;
                            } else if (numericValue < 0) {
                              numericValue = 0;
                            }

                            setPerkiraanPenghasilanNetto(
                              numericValue.toString()
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* EBupot 21/26 */}
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>EBupot 21/26</Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot 21/26
                                  </p>
                                  <img
                                    src={ScreenshotPralapor}
                                    alt="ScreenshotPralapor"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      21/26)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini tidak untuk
                                      E-Bupot 21/26)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Kategori Bupot 21/26 <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={isBupot2126Options}
                          renderInput={(params) => (
                            <TextField
                              error={error && isBupot2126.length === 0 && true}
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setIsBupot2126(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    inputan E-Bupot 21/26
                                  </p>
                                  <img
                                    src={ScreenshotPralapor}
                                    alt="ScreenshotPralapor"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini masih aktif di
                                      inputan E-Bupot 21/26)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini tidak lagi
                                      aktif di inputan E-Bupot 21/26)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Kategori Bupot 21/26 Active <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={isActiveBupot2126Options}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && isActiveBupot2126.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setIsActiveBupot2126(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Kode Objek Pajak di
                                    E-Bupot 21/26 berdasarkan pasal
                                    masing-masing
                                  </p>
                                  <img
                                    src={ScreenshotEBupot2126}
                                    alt="ScreenshotEBupot2126"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>PPh 21</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      21/26 PPh Pasal 21)
                                    </p>
                                    <li>PPh 26</li>
                                    <p>
                                      (Jika Kode Objek Pajak ini untuk E-Bupot
                                      21/26 PPh Pasal 26)
                                    </p>
                                  </ul>
                                  <p>
                                    <b>
                                      Jika bukan merupakan Kode Objek Pajak
                                      untuk E-Bupot 21/26, maka jangan diisi
                                    </b>
                                  </p>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Untuk Bupot 21/26 :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={untukBupot2126Options}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && untukBupot2126.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setUntukBupot2126(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Skema Perhitungan di
                                    E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PerhitunganPPhPasal21
                                    }
                                    alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Skema Perhitungan)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Skema Perhitungan)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Skema Perhitungan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126SkemaPenghitunganOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126SkemaPenghitungan.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126SkemaPenghitungan(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Ptkp Tahunan di E-Bupot
                                    21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PerhitunganPPhPasal21
                                    }
                                    alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Ptkp Tahunan)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Ptkp Tahunan)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Ptkp Tahunan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126PtkpTahunanOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126PtkpTahunan.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126PtkpTahunan(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Jenis Objek Pajak di
                                    E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21JenisObjekPajak
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21JenisObjekPajak"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Jenis Objek Pajak)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Jenis Objek Pajak)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Jenis Objek Pajak <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126JenisObjekPajakOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126JenisObjekPajak.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126JenisObjekPajak(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Dasar Pengenaan Pajak di
                                    E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajak"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Dasar Pengenaan Pajak)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Dasar Pengenaan Pajak)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Dasar Pengenaan Pajak <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126DasarPengenaanPajakOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126DasarPengenaanPajak.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126DasarPengenaanPajak(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Dasar Pengenaan Pajak
                                    Bebas Input di E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakBebasInput"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Dasar Pengenaan Pajak Bebas Input)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Dasar Pengenaan Pajak Bebas Input)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Dasar Pengenaan Pajak Bebas Input{" "}
                        <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={
                            bupot2126DasarPengenaanPajakBebasInputOptions
                          }
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126DasarPengenaanPajakBebasInput.length ===
                                  0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126DasarPengenaanPajakBebasInput(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan DPP Akumulasi Penghasilan
                                    Bruto Bebas Input di E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21DasarPengenaanPajakAkumulasiPenghasilanBruto"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      DPP Akumulasi Penghasilan Bruto Bebas
                                      Input)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada DPP Akumulasi Penghasilan Bruto Bebas
                                      Input)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        DPP Akumulasi Penghasilan Bruto{" "}
                        <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={
                            bupot2126DasarPengenaanPajakAkumulasiPenghasilanBrutoOptions
                          }
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto.length ===
                                  0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126DasarPengenaanPajakAkumulasiPenghasilanBruto(
                              value
                            );
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Formulasi Penghitungan
                                    Bebas Input di E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21FormulasiPerhitungan"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Formulasi Penghitungan Bebas Input)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Formulasi Penghitungan Bebas Input)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Formulasi Penghitungan <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126FormulasiPenghitunganOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126FormulasiPenghitungan.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126FormulasiPenghitungan(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Fasilitas DTP IKN Input
                                    di E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21FasilitasDtpIkn"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Fasilitas DTP IKN Input)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Fasilitas DTP IKN Input)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Fasilitas DTP IKN <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126FasilitasDtpIknOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126FasilitasDtpIkn.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126FasilitasDtpIkn(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>
                                    Untuk mengaktifkan Fasilitas SKD WPLN Input
                                    di E-Bupot 21/26
                                  </p>
                                  <img
                                    src={
                                      ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln
                                    }
                                    alt="ScreenshotEBupot2126PPhPasal21FasilitasSkdWpln"
                                    style={{ width: "550px" }}
                                  />
                                  <p>Pilihan:</p>
                                  <ul>
                                    <li>true</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 ada
                                      Fasilitas SKD WPLN Input)
                                    </p>
                                    <li>false</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26 tidak
                                      ada Fasilitas SKD WPLN Input)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Fasilitas SKD WPLN <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={bupot2126FasilitasSkdWplnOptions}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error &&
                                bupot2126FasilitasSkdWpln.length === 0 &&
                                true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setBupot2126FasilitasSkdWpln(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="6" style={textRightInfo}>
                        <div style={infoContainer}>
                          <InfoIcon
                            fontSize="small"
                            style={infoStyle}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setOpenInfoFungsi(true);
                              setInfoFungsi(
                                <div>
                                  <p>Untuk Tarif E-Bupot 21/26</p>
                                  <p>Pilihan:</p>
                                  <ul>
                                    <img
                                      src={ScreenshotEBupot2126TarifTer}
                                      alt="ScreenshotEBupot2126TarifTer"
                                      style={{ width: "500px" }}
                                    />
                                    <li>TER</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan tarif TER)
                                    </p>
                                    <hr />
                                    <img
                                      src={ScreenshotEBupot2126TarifPkp}
                                      alt="ScreenshotEBupot2126TarifPkp"
                                      style={{ width: "500px" }}
                                    />
                                    <li>PKP</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan tarif PKP)
                                    </p>
                                    <hr />
                                    <img
                                      src={
                                        ScreenshotEBupot2126TarifFormulasiPerhitungan
                                      }
                                      alt="ScreenshotEBupot2126TarifFormulasiPerhitungan"
                                      style={{ width: "500px" }}
                                    />
                                    <li>Formulasi Penghitungan</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan tarif Formulasi Penghitungan)
                                    </p>
                                    <hr />
                                    <img
                                      src={ScreenshotEBupot2126TarifBebasInput}
                                      alt="ScreenshotEBupot2126TarifBebasInput"
                                      style={{ width: "500px" }}
                                    />
                                    <li>Bebas Input</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan tarif Bebas Input)
                                    </p>
                                    <hr />
                                    <img
                                      src={
                                        ScreenshotEBupot2126TarifDitentukanJenisObjekPajak
                                      }
                                      alt="ScreenshotEBupot2126TarifDitentukanJenisObjekPajak"
                                      style={{ width: "500px" }}
                                    />
                                    <li>Ditentukan Jenis Objek Pajak</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan tarif Ditentukan Jenis Objek
                                      Pajak)
                                    </p>
                                    <hr />
                                    <img
                                      src={ScreenshotEBupot2126FixTarifPersen}
                                      alt="ScreenshotEBupot2126FixTarifPersen"
                                      style={{ width: "500px" }}
                                    />
                                    <li>Fix Tarif Persen</li>
                                    <p>
                                      (Jika Kode Objek Pajak E-Bupot 21/26
                                      menggunakan Fix Tarif Persen dari Master)
                                    </p>
                                  </ul>
                                </div>
                              );
                            }}
                          />
                        </div>
                        Tarif Bupot 21/26 <b style={colorRed}>*</b> :
                      </Form.Label>
                      <Col sm="6">
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={tarifBupot2126Options}
                          renderInput={(params) => (
                            <TextField
                              error={
                                error && tarifBupot2126.length === 0 && true
                              }
                              size="small"
                              {...params}
                            />
                          )}
                          onInputChange={(e, value) => {
                            setTarifBupot2126(value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
        <Box sx={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/objekPajak")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<SaveIcon />} type="submit">
            Simpan
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TambahObjekPajak;

const spacingTop = {
  marginTop: "20px",
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
