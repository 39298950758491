import * as React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Colors } from "../constants/styles";
import { formatDate } from "../constants/helper";

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: Colors.blue700,
    },
  },
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
});

export function ShowTableUser({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tipe User
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Cabang</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarUser/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.nama}
              </TableCell>
              <TableCell>{user.tipeUser}</TableCell>
              <TableCell>
                {user.cabang.id} - {user.cabang.namaCabang}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKategoriJaminan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Bunga/Bulan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kategoriJaminan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaKategori}
              </TableCell>
              <TableCell>{user.bungaPerBulanKategori}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisJaminan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Bunga/Bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Lama Jatuh Tempo
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pengali
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kategori</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisJaminan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaJenis}
              </TableCell>
              <TableCell>{user.bungaPerBulanJenis}</TableCell>
              <TableCell>{user.lamaJatuhTempo}</TableCell>
              <TableCell>{user.pengali}</TableCell>
              <TableCell>{user.kategorijaminan.namaKategori}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableMarketing({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Telepon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/marketing/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeMarketing}
              </TableCell>
              <TableCell>{user.namaMarketing}</TableCell>
              <TableCell>{user.teleponMarketing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKategoriKlu({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kategoriKlu/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeKategoriKlu}
              </TableCell>
              <TableCell>{user.namaKategoriKlu}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisPajak({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisPajak/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisPajak}
              </TableCell>
              <TableCell>{user.namaJenisPajak}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisSetoran({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis Pajak
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisSetoran/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisSetoran}
              </TableCell>
              <TableCell>{user.namaJenisSetoran}</TableCell>
              <TableCell>{`${user.jenispajak.kodeJenisPajak} - ${user.jenispajak.namaJenisPajak}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableObjekPajak({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis Setoran
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tarif
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis Pajak
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/objekPajak/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeObjekPajak}
              </TableCell>
              <TableCell>{user.namaObjekPajak}</TableCell>
              <TableCell>{`${user.jenissetoran.kodeJenisSetoran} - ${user.jenissetoran.namaJenisSetoran}`}</TableCell>
              <TableCell>{`${user.tarifPersen} %`}</TableCell>
              <TableCell>{`${user.jenissetoran.jenispajak.kodeJenisPajak} - ${user.jenissetoran.jenispajak.namaJenisPajak}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableGolonganPokokKlu({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kategori KLU
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/golonganPokokKlu/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeGolonganPokokKlu}
              </TableCell>
              <TableCell>{user.namaGolonganPokokKlu}</TableCell>
              <TableCell>{`${user.kategoriklu.kodeKategoriKlu} - ${user.kategoriklu.namaKategoriKlu}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableGolonganKlu({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Golongan Pokok KLU
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/golonganKlu/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeGolonganKlu}
              </TableCell>
              <TableCell>{user.namaGolonganKlu}</TableCell>
              <TableCell>{`${user.golonganpokokklu.kodeGolonganPokokKlu} - ${user.golonganpokokklu.namaGolonganPokokKlu}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSubGolonganKlu({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Golongan KLU
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/subGolonganKlu/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeSubGolonganKlu}
              </TableCell>
              <TableCell>{user.namaSubGolonganKlu}</TableCell>
              <TableCell>{`${user.golonganklu.kodeGolonganKlu} - ${user.golonganklu.namaGolonganKlu}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKelompokKegiatanEkonomiKlu({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sub Golongan KLU
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kelompokKegiatanEkonomiKlu/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeKelompokKegiatanEkonomiKlu}
              </TableCell>
              <TableCell>{user.namaKelompokKegiatanEkonomiKlu}</TableCell>
              <TableCell>{`${user.subgolonganklu.kodeSubGolonganKlu} - ${user.subgolonganklu.namaSubGolonganKlu}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePtkp({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/ptkp/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodePtkp}
              </TableCell>
              <TableCell>{user.namaPtkp}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPtkp.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTer({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              PTKP
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Min
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Max
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tarif (%)
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kategori
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/ter/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeTer}
              </TableCell>
              <TableCell>{user.ptkp.namaPtkp}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMin.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMax.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.tarifPersen} %
              </TableCell>
              <TableCell>{user.kategori}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisObjekPajak({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jenis Objek Pajak
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Objek Pajak
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisObjekPajak/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisObjekPajak}
              </TableCell>
              <TableCell>{user.namaJenisObjekPajak}</TableCell>
              <TableCell>{user.objekpajak.namaObjekPajak}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTablePkp({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Min
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Max
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tarif (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/pkp/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodePkp}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMin.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMax.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.tarifPersen} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTarifPph21PP68Tahun2009({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Min
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Max
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tarif (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/tarifPph21PP68Tahun2009/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeTarifPph21PP68Tahun2009}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMin.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMax.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.tarifPersen} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTarifPph21PP149Tahun2000({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Min
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jumlah Penghasilan Max
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tarif (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/tarifPph21PP149Tahun2000/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeTarifPph21PP149Tahun2000}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMin.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.jumlahPenghasilanMax.toLocaleString("de-DE")}
              </TableCell>
              <TableCell style={{ textAlign: "right" }}>
                {user.tarifPersen} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableNegara({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/negara/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeNegara}
              </TableCell>
              <TableCell>{user.namaNegara}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSurveyor({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Telepon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/surveyor/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeSurveyor}
              </TableCell>
              <TableCell>{user.namaSurveyor}</TableCell>
              <TableCell>{user.jenisSurveyor}</TableCell>
              <TableCell>{user.teleponSurveyor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableEksekutor({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Telepon</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/eksekutor/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeEksekutor}
              </TableCell>
              <TableCell>{user.namaEksekutor}</TableCell>
              <TableCell>{user.teleponEksekutor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTipe({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Tipe
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Rangka
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Mesin
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Isi
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Merk</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/tipe/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeTipe}
              </TableCell>
              <TableCell>{user.namaTipe}</TableCell>
              <TableCell>{user.noRangka}</TableCell>
              <TableCell>{user.noMesin}</TableCell>
              <TableCell>{user.isi}</TableCell>
              <TableCell>{user.merk}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableWarna({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Nama Warna</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/warna/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaWarna}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJenisCOA({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jenisCoa/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeJenisCOA}
              </TableCell>
              <TableCell>{user.namaJenisCOA}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableGroupCOA({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jenis COA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/groupCoa/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeGroupCOA}
              </TableCell>
              <TableCell>{user.namaGroupCOA}</TableCell>
              <TableCell>
                {user.jeniscoa.kodeJenisCOA} - {user.jeniscoa.namaJenisCOA}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableSubGroupCOA({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis COA
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Group COA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/subGroupCoa/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeSubGroupCOA}
              </TableCell>
              <TableCell>{user.namaSubGroupCOA}</TableCell>
              <TableCell>
                {user.jeniscoa.kodeJenisCOA} - {user.jeniscoa.namaJenisCOA}
              </TableCell>
              <TableCell>
                {user.groupcoa.kodeGroupCOA} - {user.groupcoa.namaGroupCOA}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCOA({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Jenis Saldo
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kas Bank
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kelompok COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Group COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sub Group COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              OJK Laba Rugi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              OJK Neraca
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              OJK Arus Kas Debet
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              OJK Arus Kas Kredit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/coa/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeCOA}
              </TableCell>
              <TableCell>{user.namaCOA}</TableCell>
              <TableCell>{user.jenisSaldo}</TableCell>
              <TableCell>{user.kasBank}</TableCell>
              <TableCell>
                {user.jeniscoa.kodeJenisCOA} - {user.jeniscoa.namaJenisCOA}
              </TableCell>
              <TableCell>
                {user.groupcoa.kodeGroupCOA} - {user.groupcoa.namaGroupCOA}
              </TableCell>
              <TableCell>
                {user.subgroupcoa.kodeSubGroupCOA} -{" "}
                {user.subgroupcoa.namaSubGroupCOA}
              </TableCell>
              <TableCell>{user.kodeCOAOJKLabaRugi}</TableCell>
              <TableCell>{user.kodeCOAOJKNeraca}</TableCell>
              <TableCell>{user.kodeCOAOJKArusKasDebet}</TableCell>
              <TableCell>{user.kodeCOAOJKArusKasKredit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableFormLabaRugiOJK({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 3
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Urutan COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Dari
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sampai
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Menu
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/formLabaRugiOJK/${user.id}`);
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: user.isMenu === true && "800" }}
              >
                {user.column1}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column2}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column3}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.kodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.urutanCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.dariKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.sampaiKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.isMenu === true && "V"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableFormNeracaOJK({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 3
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Urutan COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Dari
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sampai
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Menu
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/formNeracaOJK/${user.id}`);
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: user.isMenu === true && "800" }}
              >
                {user.column1}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column2}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column3}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.kodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.urutanCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.dariKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.sampaiKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.isMenu === true && "V"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableFormArusKasOJK({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Column 3
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Mutasi COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Urutan COA OJK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Dari
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sampai
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Menu
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/formArusKasOJK/${user.id}`);
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: user.isMenu === true && "800" }}
              >
                {user.column1}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column2}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.column3}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.kodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.mutasiOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.urutanCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.dariKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.sampaiKodeCOAOJK}
              </TableCell>
              <TableCell sx={{ fontWeight: user.isMenu === true && "800" }}>
                {user.isMenu === true && "V"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTahun({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tahun
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/tahun/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.tahun}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCabang({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Telepon
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>PIC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/cabang/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell>{user.namaCabang}</TableCell>
              <TableCell>{user.alamatCabang}</TableCell>
              <TableCell>{user.teleponCabang}</TableCell>
              <TableCell>{user.picCabang}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableGantiPeriode({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Dari Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Sampai Tanggal
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/gantiPeriode/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaPeriode}
              </TableCell>
              <TableCell>{user.dariTanggal}</TableCell>
              <TableCell>{user.sampaiTanggal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKategoriProvinsi({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/provinsi/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell>{user.namaProvinsi}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKabupaten({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Provinsi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kabupaten/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell>{user.namaKabupaten}</TableCell>
              <TableCell>
                {user.provinsis.id} - {user.provinsis.namaProvinsi}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKecamatan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Provinsi
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kabupaten</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kecamatan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell>{user.namaKecamatan}</TableCell>
              <TableCell>
                {user.provinsis.id} - {user.provinsis.namaProvinsi}
              </TableCell>
              <TableCell>
                {user.kabupaten.id} - {user.kabupaten.namaKabupaten}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKelurahan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Pos
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Provinsi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kabupaten
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kecamatan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/kelurahan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.id}
              </TableCell>
              <TableCell>{user.namaKelurahan}</TableCell>
              <TableCell>{user.kodePos}</TableCell>
              <TableCell>
                {user.provinsis.id} - {user.provinsis.namaProvinsi}
              </TableCell>
              <TableCell>
                {user.kabupaten.id} - {user.kabupaten.namaKabupaten}
              </TableCell>
              <TableCell>
                {user.kecamatan.id} - {user.kecamatan.namaKecamatan}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableCustomer({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama/NIK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tpt/Tgl Lahir
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              JK
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pkj
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Cr. By
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/customer/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                <p>{user.namaCustomer}</p>
                <p>{user.nikCustomer}</p>
              </TableCell>
              <TableCell>
                <p>{user.tempatLahirCustomer}</p>
                <p>{formatDate(user.tanggalLahirCustomer)}</p>
              </TableCell>
              <TableCell>
                {user.jenisKelaminCustomer === "LAKI-LAKI" ? "L" : "P"}
              </TableCell>
              <TableCell>{user.alamatCustomer}</TableCell>
              <TableCell>{user.pekerjaanCustomer}</TableCell>
              <TableCell>{user.user ? user.user.username : "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarPengajuan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. SBG
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarPengajuan/pengajuan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noSbg}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarInfoNasabahGadai({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. SBG
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarInfoNasabahGadai/infoNasabahGadai/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noSbg}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJaminan({ currentPosts, pengajuanId }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jaminan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ket.
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Harga Tafsiran Rp.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarPengajuan/pengajuan/${pengajuanId}/${user.id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaJam}
              </TableCell>
              <TableCell>{user.ketJam}</TableCell>
              <TableCell align="right">
                {user.hargaTafsirJam.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJaminanInfoNasabahGadai({
  currentPosts,
  pengajuanId,
}) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jaminan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ket.
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Harga Tafsiran Rp.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaJam}
              </TableCell>
              <TableCell>{user.ketJam}</TableCell>
              <TableCell align="right">
                {user.hargaTafsirJam.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJaminanInApproval({ currentPosts, pengajuanId }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jaminan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ket.
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Harga Tafsiran Rp.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.namaJam}
              </TableCell>
              <TableCell>{user.ketJam}</TableCell>
              <TableCell align="right">
                {user.hargaTafsirJam.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarApproval({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>User Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarApproval/approval/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSbg}
              </TableCell>
              <TableCell>{user.noAju}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableApproval({ currentPosts }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Jaminan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ket.
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              Harga Tafsiran Rp.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
              }}
            >
              <TableCell component="th" scope="row">
                {user.namaJam}
              </TableCell>
              <TableCell>{user.ketJam}</TableCell>
              <TableCell align="right">
                {user.hargaTafsirJam.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBuktiPencairan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarBuktiPencairan/buktiPencairan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSbg}
              </TableCell>
              <TableCell>{user.noAju}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarTopUp({ currentPosts, searchTerm }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts
            .filter((val) => {
              if (searchTerm === "") {
                return val;
              } else if (
                val.noSbg
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.noAju
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.tanggalAju
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.customer.namaCustomer
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.customer.alamatCustomer
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.coa.kodeCOA
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase()) ||
                val.pinjamanAju == searchTerm ||
                val.user.username
                  .toString()
                  .toUpperCase()
                  .includes(searchTerm.toUpperCase())
              ) {
                return val;
              }
            })
            .map((user, index) => (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarTopup/topup/${user.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.noSbg}
                </TableCell>
                <TableCell>{user.noAju}</TableCell>
                <TableCell>{user.tanggalAju}</TableCell>
                <TableCell>{user.customer.namaCustomer}</TableCell>
                <TableCell>{user.customer.alamatCustomer}</TableCell>
                <TableCell>{user.coa.kodeCOA}</TableCell>
                <TableCell align="right">
                  {user.pinjamanAju.toLocaleString("de-DE")}
                </TableCell>
                <TableCell>{user.user.username}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarApprovalTopUp({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarTopup/topup/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSbg}
              </TableCell>
              <TableCell>{user.noAju}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableTopup({ currentPosts, topupId }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nilai Topup
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Tgl. Topup</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarTopup/topup/${topupId}/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noKwitansi}
              </TableCell>
              <TableCell align="right">
                {user.nilaiTopup.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.tglTopup}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarKasMasuk({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarKasMasuk/kasMasuk/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noBukti}
              </TableCell>
              <TableCell>{user.tglKasMasuk}</TableCell>
              <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
              <TableCell>{user.keterangan}</TableCell>
              <TableCell align="right">
                {user.jumlah.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKasMasuk({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let newTglKasMasuk = new Date(user.kasmasuk.tglKasMasuk);
            let tempTglKasMasuk = `${newTglKasMasuk
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(newTglKasMasuk.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${newTglKasMasuk.getFullYear()}`;
            return (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarKasMasuk/kasMasuk/${id}/${user.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.kasmasuk.noBukti}
                </TableCell>
                <TableCell>{tempTglKasMasuk}</TableCell>
                <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarKasKeluar({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarKasKeluar/kasKeluar/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noBukti}
              </TableCell>
              <TableCell>{user.tglKasKeluar}</TableCell>
              <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
              <TableCell>{user.keterangan}</TableCell>
              <TableCell align="right">
                {user.jumlah.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableKasKeluar({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Kas Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let newTglKasKeluar = new Date(user.kaskeluar.tglKasKeluar);
            let tempTglKasKeluar = `${newTglKasKeluar
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(newTglKasKeluar.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${newTglKasKeluar.getFullYear()}`;
            return (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarKasKeluar/kasKeluar/${id}/${user.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.kaskeluar.noBukti}
                </TableCell>
                <TableCell>{tempTglKasKeluar}</TableCell>
                <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBankMasuk({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarBankMasuk/bankMasuk/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noBukti}
              </TableCell>
              <TableCell>{user.tglBankMasuk}</TableCell>
              <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
              <TableCell>{user.keterangan}</TableCell>
              <TableCell align="right">
                {user.jumlah.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBankMasuk({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Masuk
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let newTglBankMasuk = new Date(user.bankmasuk.tglBankMasuk);
            let tempTglBankMasuk = `${newTglBankMasuk
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(newTglBankMasuk.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${newTglBankMasuk.getFullYear()}`;
            return (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarBankMasuk/bankMasuk/${id}/${user.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.bankmasuk.noBukti}
                </TableCell>
                <TableCell>{tempTglBankMasuk}</TableCell>
                <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBankKeluar({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarBankKeluar/bankKeluar/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noBukti}
              </TableCell>
              <TableCell>{user.tglBankKeluar}</TableCell>
              <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
              <TableCell>{user.keterangan}</TableCell>
              <TableCell align="right">
                {user.jumlah.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableBankKeluar({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bank Keluar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              COA
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Jumlah</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => {
            let newTglBankKeluar = new Date(user.bankkeluar.tglBankKeluar);
            let tempTglBankKeluar = `${newTglBankKeluar
              .getDate()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              })}/${(newTglBankKeluar.getMonth() + 1).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}/${newTglBankKeluar.getFullYear()}`;
            return (
              <TableRow
                key={user.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(`/daftarBankKeluar/bankKeluar/${id}/${user.id}`);
                }}
              >
                <TableCell component="th" scope="row">
                  {user.bankkeluar.noBukti}
                </TableCell>
                <TableCell>{tempTglBankKeluar}</TableCell>
                <TableCell>{`${user.coa.kodeCOA} - ${user.coa.namaCOA}`}</TableCell>
                <TableCell>{user.keterangan}</TableCell>
                <TableCell align="right">
                  {user.jumlah.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableNeracaSaldo({
  currentPosts,
  totalDebet,
  totalKredit,
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama Rekening
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Saldo Awal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Debet
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kredit
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Saldo Akhir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user._id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
            >
              <TableCell component="th" scope="row">
                {user.kodeCOA}
              </TableCell>
              <TableCell>{user.namaCOA}</TableCell>
              <TableCell align="right">
                {parseInt(user.saldoAwal).toLocaleString("de-DE")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.debet).toLocaleString("de-DE")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.kredit).toLocaleString("de-DE")}
              </TableCell>
              <TableCell align="right">
                {parseInt(user.saldoAkhir).toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
          <TableRow
            key={"count"}
            sx={{
              bgcolor: Colors.grey300,
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 700 }}>Balance :</Typography>
            </TableCell>
            <TableCell align="right">
              {parseInt(totalDebet).toLocaleString("de-DE")}
            </TableCell>
            <TableCell align="right">
              {parseInt(totalKredit).toLocaleString("de-DE")}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow
            key={"count"}
            sx={{
              bgcolor: Colors.grey300,
            }}
          >
            <TableCell component="th" scope="row"></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <Typography sx={{ fontWeight: 700 }}>Selisih :</Typography>
            </TableCell>
            <TableCell align="right">
              {totalDebet - totalKredit > 1 || totalDebet - totalKredit < -1
                ? (totalDebet - totalKredit).toLocaleString("de-DE")
                : 0}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarTebus({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarTebus/tebus/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSbg}
              </TableCell>
              <TableCell>{user.noAju}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {(user.pinjamanAju + user.nilaiTopup).toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarJurnalUmum({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Bukti
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tanggal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Debet
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kredit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
                backgroundColor:
                  user.totalDebet - user.totalKredit !== 0
                    ? "#f79892"
                    : "white",
              }}
              onClick={() => {
                navigate(`/daftarJurnalUmum/jurnalUmum/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noBukti}
              </TableCell>
              <TableCell>{user.tglJurnalUmum}</TableCell>
              <TableCell align="right">
                {user.totalDebet.toLocaleString("de-DE")}
              </TableCell>
              <TableCell align="right">
                {user.totalKredit.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJurnalUmum({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Account
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterangan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Debet
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kredit
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>OP ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((jurnalUmumChild, index) => (
            <TableRow
              key={jurnalUmumChild.kodeStok}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarJurnalUmum/jurnalUmum/${id}/${jurnalUmumChild.id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {jurnalUmumChild.coa.kodeCOA}
              </TableCell>
              <TableCell>{jurnalUmumChild.coa.namaCOA}</TableCell>
              <TableCell>{jurnalUmumChild.keterangan}</TableCell>
              <TableCell align="right">
                {jurnalUmumChild.debet.toLocaleString("de-DE")}
              </TableCell>
              <TableCell align="right">
                {jurnalUmumChild.kredit.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{jurnalUmumChild.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarPerpanjangan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Perp.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt. Tempo 1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt. Tempo 2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarPerpanjangan/perpanjangan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noKwitansi}
              </TableCell>
              <TableCell>{user.noSbg}</TableCell>
              <TableCell>{user.tglPerpanjangan}</TableCell>
              <TableCell>{user.tglJtTempo1}</TableCell>
              <TableCell>{user.tglJtTempo2}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarCicilan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Cicilan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Cicilan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarCicilan/cicilan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noKwitansi}
              </TableCell>
              <TableCell>{user.noSbg}</TableCell>
              <TableCell>{user.tglCicilan}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.cicilan.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableJualAgunan({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jual
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Sbg
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pembeli
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Agunan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/jualAgunan/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noJual}
              </TableCell>
              <TableCell>{user.tglJual}</TableCell>
              <TableCell>{user.noSbg}</TableCell>
              <TableCell>{user.namaPembeli}</TableCell>
              <TableCell>{user.jaminan.ketJam}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarPengajuanFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Alamat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kasir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarPengajuanFidusia/pengajuanFidusia/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.customer.alamatCustomer}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarApprovalFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>User Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                bgcolor: user.isReject === true && Colors.red400,
                cursor: "pointer",
              }}
              onClick={() => {
                if (user.isReject === true) {
                  navigate(
                    `/daftarApprovalFidusia/approvalFidusia/reject/${user.id}`
                  );
                } else {
                  navigate(`/daftarApprovalFidusia/approvalFidusia/${user.id}`);
                }
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarPenarikanFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>User Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                if (user.isReject === true) {
                  navigate(
                    `/daftarPenarikanFidusia/penarikanFidusia/reject/${user.id}`
                  );
                } else {
                  navigate(
                    `/daftarPenarikanFidusia/penarikanFidusia/${user.id}`
                  );
                }
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarBuktiPencairanFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Pinjaman
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>User Approve</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarBuktiPencairanFidusia/buktiPencairanFidusia/${user.id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
              <TableCell>{user.user.username}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarAngsuranFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Pinjaman</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarAngsuranFidusia/angsuranFidusia/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarInfoNasabahFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Aju
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Atas Nama
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Kode Kas
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Pinjaman</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarInfoNasabahFidusia/infoNasabahFidusia/${user.id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {user.noAju}
              </TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.tanggalAju}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>{user.customer.namaCustomer}</TableCell>
              <TableCell>{user.coa.kodeCOA}</TableCell>
              <TableCell align="right">
                {user.pinjamanAju.toLocaleString("de-DE")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuran({ id, currentPosts, totalSaldo, dataStart }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bayar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterlambatan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Modal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Bunga
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A/bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Hutang Denda
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Denda
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Potongan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => {
            if (dataStart > 0 && index === 0) {
              let tempTotalAngPerBulan = angsuran.angPerBulan * dataStart;
              totalSaldo -= tempTotalAngPerBulan;
            }

            totalSaldo -= angsuran.angPerBulan;
            return (
              <TableRow
                key={angsuran.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: angsuran.isPercepatan && Colors.blue50,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/daftarAngsuranFidusia/angsuranFidusia/${id}/${angsuran.no}`
                  );
                }}
              >
                <TableCell component="th" scope="row">
                  {angsuran.tglBayar}
                </TableCell>
                <TableCell>{angsuran.tglJatuhTempo}</TableCell>
                <TableCell>{angsuran.keterlambatan} hari</TableCell>
                <TableCell>{angsuran.noKwitansi}</TableCell>
                <TableCell>{angsuran.no}</TableCell>
                <TableCell align="right">
                  {parseInt(angsuran.angModal).toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {parseInt(angsuran.angBunga).toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angPerBulan.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.hutangDenda.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.denda.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.potongan.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {totalSaldo.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuranInfoNasabah({
  id,
  currentPosts,
  totalSaldo,
  dataStart,
}) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Bayar
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Jt
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Keterlambatan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Kwitansi
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Modal
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A. Bunga
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              A/bulan
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Hutang Denda
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Denda
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Potongan
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => {
            if (dataStart > 0 && index === 0) {
              let tempTotalAngPerBulan = angsuran.angPerBulan * dataStart;
              totalSaldo -= tempTotalAngPerBulan;
            }

            totalSaldo -= angsuran.angPerBulan;
            return (
              <TableRow
                key={angsuran.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { bgcolor: Colors.grey300 },
                  bgcolor: angsuran.isPercepatan && Colors.blue50,
                }}
              >
                <TableCell component="th" scope="row">
                  {angsuran.tglBayar}
                </TableCell>
                <TableCell>{angsuran.tglJatuhTempo}</TableCell>
                <TableCell>{angsuran.keterlambatan} hari</TableCell>
                <TableCell>{angsuran.noKwitansi}</TableCell>
                <TableCell>{angsuran.no}</TableCell>
                <TableCell align="right">
                  {parseInt(angsuran.angModal).toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {parseInt(angsuran.angBunga).toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.angPerBulan.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.hutangDenda.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.denda.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {angsuran.potongan.toLocaleString("de-DE")}
                </TableCell>
                <TableCell align="right">
                  {totalSaldo.toLocaleString("de-DE")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuranSP({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP3
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>S. Panggilan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => (
            <TableRow
              key={angsuran.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
              }}
            >
              <TableCell component="th" scope="row">
                {angsuran.no}
              </TableCell>
              <TableCell>{angsuran.sp1}</TableCell>
              <TableCell>{angsuran.sp2}</TableCell>
              <TableCell>{angsuran.sp3}</TableCell>
              <TableCell>{angsuran.sPanggilan}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableAngsuranSPInfoNasabah({ id, currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No.
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP1
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP2
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              SP3
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>S. Panggilan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((angsuran, index) => (
            <TableRow
              key={angsuran.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
              }}
            >
              <TableCell component="th" scope="row">
                {angsuran.no}
              </TableCell>
              <TableCell>{angsuran.sp1}</TableCell>
              <TableCell>{angsuran.sp2}</TableCell>
              <TableCell>{angsuran.sp3}</TableCell>
              <TableCell>{angsuran.sPanggilan}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarSp1Fidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ang. Ke-
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Atas Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarSp1Fidusia/sp1Fidusia/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSurat}
              </TableCell>
              <TableCell>{user.tglSurat}</TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.no}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>
                {user.angsuranfidusia.pengajuanfidusia.customer.namaCustomer}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarSp2Fidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ang. Ke-
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Atas Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarSp2Fidusia/sp2Fidusia/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSurat}
              </TableCell>
              <TableCell>{user.tglSurat}</TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.no}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>
                {user.angsuranfidusia.pengajuanfidusia.customer.namaCustomer}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarSp3Fidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ang. Ke-
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Atas Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/daftarSp3Fidusia/sp3Fidusia/${user.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSurat}
              </TableCell>
              <TableCell>{user.tglSurat}</TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.no}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>
                {user.angsuranfidusia.pengajuanfidusia.customer.namaCustomer}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function ShowTableDaftarSuratPanggilanFidusia({ currentPosts }) {
  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table aria-label="simple table">
        <TableHead className={classes.root}>
          <TableRow>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Surat
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              No. Perjanjian
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Ang. Ke-
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold" }}
              className={classes.tableRightBorder}
            >
              Tgl. Approval
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Atas Nama</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentPosts.map((user, index) => (
            <TableRow
              key={user.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:hover": { bgcolor: Colors.grey300 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/daftarSuratPanggilanFidusia/suratPanggilanFidusia/${user.id}`
                );
              }}
            >
              <TableCell component="th" scope="row">
                {user.noSurat}
              </TableCell>
              <TableCell>{user.tglSurat}</TableCell>
              <TableCell>{user.noPerjanjian}</TableCell>
              <TableCell>{user.no}</TableCell>
              <TableCell>{user.tglApproval}</TableCell>
              <TableCell>
                {user.angsuranfidusia.pengajuanfidusia.customer.namaCustomer}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
