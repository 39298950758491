import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import SaveIcon from "@mui/icons-material/Save";

const TambahTer = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodePtkp, setKodePtkp] = useState("");
  const [jumlahPenghasilanMin, setJumlahPenghasilanMin] = useState("");
  const [jumlahPenghasilanMax, setJumlahPenghasilanMax] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  let kategoriOptions = [
    {
      label: "A",
    },
    {
      label: "B",
    },
    {
      label: "C",
    },
  ];
  const [kategori, setKategori] = useState("");

  const [ptkps, setPtkps] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let ptkpOptions = ptkps.map((ptkp) => ({
    label: `${ptkp.kodePtkp} - ${ptkp.namaPtkp}`,
  }));

  useEffect(() => {
    getPtkpData();
  }, []);

  const getPtkpData = async () => {
    const response = await axios.post(`${tempUrl}/ptkps`, {
      _id: user.id,
      token: user.token,
    });
    setPtkps(response.data);
  };

  const saveTer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodePtkp.length !== 0 &&
      jumlahPenghasilanMin.length !== 0 &&
      jumlahPenghasilanMax.length !== 0 &&
      tarifPersen.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveTer`, {
          kodePtkp,
          jumlahPenghasilanMin,
          jumlahPenghasilanMax,
          tarifPersen,
          kategori,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/ter");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah TER</h5>
      <hr />
      <Card>
        <Card.Header>TER</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveTer}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode PTKP <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={ptkpOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodePtkp.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodePtkp(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Jumlah Penghasilan Min <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMin}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMin(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Jumlah Penghasilan Max <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMax}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMax(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Tarif Persen (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <NumericFormat
                      required
                      value={tarifPersen}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setTarifPersen(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kategori <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={kategoriOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kategori.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKategori(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/ter")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahTer;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
