import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import * as XLSX from "xlsx";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ImporUser = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch, setting } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");

  const [openFormatNamaFileSalah, setOpenFormatNamaFileSalah] = useState(false);
  const fileInputSertifikatElektronikRef = useRef(null);

  const handleButtonClickSertifikatElektronik = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fileInputSertifikatElektronikRef.current.click();
  };

  const [openConfirmationSaved, setOpenConfirmationSaved] = useState(false);
  const handleCloseConfirmationSaved = () => {
    setOpenConfirmationSaved(false);
  };

  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [jsonData, setJsonData] = useState({});
  const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

  const resetFileInput = () => {
    if (fileInputSertifikatElektronikRef.current) {
      fileInputSertifikatElektronikRef.current.value = null;
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // Check if there's a file
    if (!file) return;

    // Check file size
    if (file && file.size > maxFileSize) {
      alert("Ukuran file melebihi 2MB. Harap upload file yang lebih kecil.");
      resetFileInput();
      return;
    }

    // if (!file.name.includes(user.npwp15)) {
    //   setOpenFormatNamaFileSalah(true);
    //   resetFileInput();
    //   return;
    // }

    // Store the file name in state
    setFileName(file.name);

    // Check file size and convert it to KB or MB
    let fileSizeDisplay;
    if (file) {
      const fileSizeInKB = parseInt(file.size / 1024);
      fileSizeDisplay =
        file.size < 1024 * 1024
          ? `${fileSizeInKB} KB`
          : `${parseInt(file.size / (1024 * 1024))} MB`;
    }
    setFileSize(fileSizeDisplay);

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetsData = {};

      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const jsonSheetData = XLSX.utils.sheet_to_json(worksheet);
        sheetsData[sheetName] = jsonSheetData;
      });

      setJsonData(sheetsData);

      resetFileInput();

      // Process Data
      console.log("File name:", file.name);
      console.log(sheetsData["User"]);
      console.log(sheetsData);
    };

    if (file) {
      reader.readAsArrayBuffer(file);
    }
  };

  // Data Utama
  const [nama, setNama] = useState("");
  const [tipeUser, setTipeUser] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [cabangs, setCabangs] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openAlertNama, setOpenAlertNama] = useState(false);

  const handleClickOpenAlertNama = () => {
    setOpenAlertNama(true);
  };

  const handleCloseAlertNama = () => {
    setOpenAlertNama(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getCabangsData();
  }, []);

  const getCabangsData = async () => {
    setKodeCabang("");
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
    });
    setCabangs(response.data);
    if (user.tipeUser === "OWNER") {
      setTipeUser("OWNER");
      setKodeCabang(response.data[0].id);
    } else {
      setTipeUser("MANAGER");
      setKodeCabang(user.cabang.id);
    }
  };

  const imporData = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;

    let tempCondition = form.checkValidity() && fileName.length !== 0;

    if (tempCondition) {
      handleCloseConfirmationSaved(e);

      setLoading(true);

      let savedUserData = await axios.post(`${tempUrl}/auth/saveUserData`, {
        userId: user.id,

        jsonData,

        userIdInput: user.id,
        cabangId: kodeCabang,
        _id: user.id,
        token: user.token,
      });

      navigate(`/daftarUser`);
    } else {
      setValidated(true);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Impor User</h5>
      <Dialog
        open={openAlertNama}
        onClose={handleCloseAlertNama}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data Nama Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nama ${nama} sudah ada, ganti Nama!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlertNama}>Ok</Button>
        </DialogActions>
      </Dialog>
      <hr />
      <Card>
        <Card.Header>User</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={imporData}>
            <Card>
              <Card.Header>Data Utama</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4">
                        File (*.xlsx)
                      </Form.Label>
                      <Col sm="8">
                        {fileName.length === 0 ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleButtonClickSertifikatElektronik}
                          >
                            Pilih File...
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleButtonClickSertifikatElektronik}
                          >
                            Ubah File...
                          </Button>
                        )}
                        <div>
                          <Form.Control
                            type="file"
                            accept=".xlsx"
                            ref={fileInputSertifikatElektronikRef}
                            onChange={handleFileUpload}
                            style={{ display: "none" }} // Hide the file input
                          />
                          <Form.Label>
                            Ukuran file maksimal 2MB berformat .xlsx
                          </Form.Label>
                          <Form.Label style={{ color: "#fd397a" }}></Form.Label>
                        </div>
                        <div>
                          <div
                            style={{
                              color: "black",
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <p>{fileName}</p>
                            <p>{fileSize}</p>
                            {fileName.length !== 0 && (
                              <Button
                                className="delete-button"
                                onClick={() => {
                                  setFileName("");
                                  setFileSize("");
                                  setJsonData({});
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </Button>
                            )}
                          </div>
                        </div>
                        {/* {Object.keys(jsonData).length > 0 && (
                          <div>
                            {Object.keys(jsonData).map((sheetName) => (
                              <div key={sheetName}>
                                <h3>Sheet: {sheetName}</h3>
                                <pre>
                                  {JSON.stringify(jsonData[sheetName], null, 2)}
                                </pre>
                              </div>
                            ))}
                          </div>
                        )} */}
                      </Col>
                    </Form.Group>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}></Box>
                </Box>
              </Card.Body>
            </Card>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarUser")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ImporUser;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const showDataContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const checkboxTitle = {
  marginBottom: 0,
};

const secondCheckboxTitle = {
  marginTop: 15,
  marginBottom: 0,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
