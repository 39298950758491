import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const UbahUser = () => {
  const { screenSize } = useStateContext();
  const { user, setting, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeCabang, setKodeCabang] = useState("");
  // Data Utama
  const [npwp15, setNpwp15] = useState("");
  const [nikNpwp16, setNikNpwp16] = useState("");
  const [nitku, setNitku] = useState("");
  const [nama, setNama] = useState("");
  const [namaLama, setNamaLama] = useState("");
  const [bentukBadan, setBentukBadan] = useState("");

  // Data Lainnya
  const [alamat, setAlamat] = useState("");
  const [nomorTelepon, setNomorTelepon] = useState("");
  const [email, setEmail] = useState("");

  // Data KLU
  const [kodeKelompokKegiatanEkonomiKlu, setKodeKelompokKegiatanEkonomiKlu] =
    useState("");

  // Info Perpajakan
  const [kppAdministrasi, setKppAdministrasi] = useState("");
  const [noTeleponKpp, setNoTeleponKpp] = useState("");
  const [accountRepresentative, setAccountRepresentative] = useState("");
  const [statusWp, setStatusWp] = useState("");
  const [statusPkp, setStatusPkp] = useState("");

  // Identitas Penanggung Jawab
  const [namaPenanggungJawab, setNamaPenanggungJawab] = useState("");
  const [nikPenanggungJawab, setNikPenanggungJawab] = useState("");
  const [npwpPenanggungJawab, setNpwpPenanggungJawab] = useState("");
  const [jabatanPenanggungJawab, setJabatanPenanggungJawab] = useState("");
  const [kebangsaanPenanggungJawab, setKebangsaanPenanggungJawab] =
    useState("");
  const [alamatPjBadanPenanggungJawab, setAlamatPjBadanPenanggungJawab] =
    useState("");

  // Dokumen Pendirian
  const [nomorAkta, setNomorAkta] = useState("");
  const [tempatAkta, setTempatAkta] = useState("");
  const [namaNotaris, setNamaNotaris] = useState("");
  const [nomorAktaPerubahan, setNomorAktaPerubahan] = useState("");
  const [tipeUser, setTipeUser] = useState("");
  const [password, setPassword] = useState("");
  const [passphrase, setPassphrase] = useState("");

  // Akses Master
  const [kategoriKlu, setKategoriKlu] = useState(false);
  const [golonganPokokKlu, setGolonganPokokKlu] = useState(false);
  const [golonganKlu, setGolonganKlu] = useState(false);
  const [subGolonganKlu, setSubGolonganKlu] = useState(false);
  const [kelompokKegiatanEkonomiKlu, setKelompokKegiatanEkonomiKlu] =
    useState(false);
  const [jenisPajak, setJenisPajak] = useState(false);
  const [jenisSetoran, setJenisSetoran] = useState(false);
  const [objekPajak, setObjekPajak] = useState(false);
  const [ptkp, setPtkp] = useState(false);
  const [ter, setTer] = useState(false);
  const [jenisObjekPajak, setJenisObjekPajak] = useState(false);
  const [pkp, setPkp] = useState(false);
  const [tarifPph21PP68Tahun2009, setTarifPph21PP68Tahun2009] = useState(false);
  const [tarifPph21PP149Tahun2000, setTarifPph21PP149Tahun2000] =
    useState(false);
  const [negara, setNegara] = useState(false);
  const [tahun, setTahun] = useState(false);
  const [cabang, setCabang] = useState(false);

  // Akses Utility
  const [profilUser, setProfilUser] = useState(false);
  const [daftarUser, setDaftarUser] = useState(false);
  const [settingAkses, setSettingAkses] = useState(false);

  // Akses Kewajiban Perpajakan
  const [pphPasal25, setPphPasal25] = useState(false);
  const [pphPasal29, setPphPasal29] = useState(false);
  const [pphFinal, setPphFinal] = useState(false);
  const [pphPasal4Ayat2, setPphPasal4Ayat2] = useState(false);
  const [pphPasal15, setPphPasal15] = useState(false);
  const [pphPasal19, setPphPasal19] = useState(false);
  const [pphPasal21, setPphPasal21] = useState(false);
  const [pphPasal23, setPphPasal23] = useState(false);
  const [pphPasal26, setPphPasal26] = useState(false);

  // Check All Akses
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllKewajiban, setCheckAllKewajiban] = useState(false);

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [kelompokKegiatanEkonomiKlus, setKelompokKegiatanEkonomiKlus] =
    useState([]);
  const [cabangs, setCabangs] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const tipeUserOption = ["MANAGER", "ADMIN"];
  const tipeUserOptionOwner = ["OWNER", "MANAGER", "ADMIN"];

  const statusWpOption = ["AKTIF", "TIDAK AKTIF"];
  const statusPkpOption = ["NON PKP", "PKP"];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let kelompokKegiatanEkonomiKluOptions = kelompokKegiatanEkonomiKlus.map(
    (kelompokKegiatanEkonomiKlu) => ({
      label: `${kelompokKegiatanEkonomiKlu.kodeKelompokKegiatanEkonomiKlu} - ${kelompokKegiatanEkonomiKlu.namaKelompokKegiatanEkonomiKlu}`,
    })
  );

  useEffect(() => {
    getKelompokKegiatanEkonomiKluData();
    getCabangsData();
    getUserById();
  }, []);

  const getKelompokKegiatanEkonomiKluData = async () => {
    const response = await axios.post(
      `${tempUrl}/kelompokKegiatanEkonomiKlus`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    setKelompokKegiatanEkonomiKlus(response.data);
  };

  const getCabangsData = async () => {
    const response = await axios.post(`${tempUrl}/cabangs`, {
      _id: user.id,
      token: user.token,
    });
    setCabangs(response.data);
  };

  const getUserById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/findUser/${id}`, {
      _id: user.id,
      token: user.token,
    });
    // Data Utama
    setNpwp15(response.data.npwp15);
    setNikNpwp16(response.data.nikNpwp16);
    setNitku(response.data.nitku);
    setNama(response.data.nama);
    setNamaLama(response.data.nama);
    setBentukBadan(response.data.bentukBadan);

    // Data Lainnya
    setAlamat(response.data.alamat);
    setNomorTelepon(response.data.nomorTelepon);
    setEmail(response.data.email);

    // Data KLU
    setKodeKelompokKegiatanEkonomiKlu(
      `${response.data.kelompokkegiatanekonomiklu.kodeKelompokKegiatanEkonomiKlu} - ${response.data.kelompokkegiatanekonomiklu.namaKelompokKegiatanEkonomiKlu}`
    );

    // Info Perpajakan
    setKppAdministrasi(response.data.kppAdministrasi);
    setNoTeleponKpp(response.data.noTeleponKpp);
    setAccountRepresentative(response.data.accountRepresentative);
    setStatusWp(response.data.statusWp);
    setStatusPkp(response.data.statusPkp);

    // Identitas Penanggung Jawab
    setNamaPenanggungJawab(response.data.namaPenanggungJawab);
    setNikPenanggungJawab(response.data.nikPenanggungJawab);
    setNpwpPenanggungJawab(response.data.npwpPenanggungJawab);
    setJabatanPenanggungJawab(response.data.jabatanPenanggungJawab);
    setKebangsaanPenanggungJawab(response.data.kebangsaanPenanggungJawab);
    setAlamatPjBadanPenanggungJawab(response.data.alamatPjBadanPenanggungJawab);

    // Dokumen Pendirian
    setNomorAkta(response.data.nomorAkta);
    setTempatAkta(response.data.tempatAkta);
    setNamaNotaris(response.data.namaNotaris);
    setNomorAktaPerubahan(response.data.nomorAktaPerubahan);
    setTipeUser(response.data.tipeUser);
    setKodeCabang(response.data.cabang.id);

    // Akses Master
    setKategoriKlu(response.data.akses.kategoriKlu);
    setGolonganPokokKlu(response.data.akses.golonganPokokKlu);
    setGolonganKlu(response.data.akses.golonganKlu);
    setSubGolonganKlu(response.data.akses.subGolonganKlu);
    setKelompokKegiatanEkonomiKlu(
      response.data.akses.kelompokKegiatanEkonomiKlu
    );
    setJenisPajak(response.data.akses.jenisPajak);
    setJenisSetoran(response.data.akses.jenisSetoran);
    setObjekPajak(response.data.akses.objekPajak);
    setPtkp(response.data.akses.ptkp);
    setTer(response.data.akses.ter);
    setJenisObjekPajak(response.data.akses.jenisObjekPajak);
    setPkp(response.data.akses.pkp);
    setTarifPph21PP68Tahun2009(response.data.akses.tarifPph21PP68Tahun2009);
    setTarifPph21PP149Tahun2000(response.data.akses.tarifPph21PP149Tahun2000);
    setNegara(response.data.akses.negara);
    setTahun(response.data.akses.tahun);
    setCabang(response.data.akses.cabang);
    setSettingAkses(response.data.akses.setting);

    // Akses Utility
    setProfilUser(response.data.akses.profilUser);
    setDaftarUser(response.data.akses.daftarUser);

    // Akses Kewajiban Perpajakan
    setPphPasal25(response.data.kewajibanPerpajakan.pphPasal25);
    setPphPasal29(response.data.kewajibanPerpajakan.pphPasal29);
    setPphFinal(response.data.kewajibanPerpajakan.pphFinal);
    setPphPasal4Ayat2(response.data.kewajibanPerpajakan.pphPasal4Ayat2);
    setPphPasal15(response.data.kewajibanPerpajakan.pphPasal15);
    setPphPasal19(response.data.kewajibanPerpajakan.pphPasal19);
    setPphPasal21(response.data.kewajibanPerpajakan.pphPasal21);
    setPphPasal23(response.data.kewajibanPerpajakan.pphPasal23);
    setPphPasal26(response.data.kewajibanPerpajakan.pphPasal26);

    setLoading(false);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        let tempNama = await axios.post(`${tempUrl}/getNama`, {
          nama,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        let isNamaNotValid = tempNama.data.length > 0 && nama !== namaLama;
        if (isNamaNotValid) {
          handleClickOpenAlert();
        } else {
          setLoading(true);
          if (password.length === 0) {
            setPassword(user.password);
          }
          await axios.post(`${tempUrl}/users/${id}`, {
            // Data Utama
            npwp15,
            nikNpwp16,
            nitku,
            nama,
            bentukBadan,

            // Data Lainnya
            alamat,
            nomorTelepon,
            email,

            // Data KLU
            kodeKelompokKegiatanEkonomiKlu:
              kodeKelompokKegiatanEkonomiKlu.split(" ", 1)[0],

            // Info Perpajakan
            kppAdministrasi,
            noTeleponKpp,
            accountRepresentative,
            statusWp,
            statusPkp,

            // Identitas Penanggung Jawab
            namaPenanggungJawab,
            nikPenanggungJawab,
            npwpPenanggungJawab,
            jabatanPenanggungJawab,
            kebangsaanPenanggungJawab,
            alamatPjBadanPenanggungJawab,

            // Dokumen Pendirian
            nomorAkta,
            tempatAkta,
            namaNotaris,
            nomorAktaPerubahan,
            tipeUser,
            password,
            passphrase,
            tipeAdmin: user.tipeUser,
            akses: {
              kategoriKlu,
              golonganPokokKlu,
              golonganKlu,
              subGolonganKlu,
              kelompokKegiatanEkonomiKlu,
              jenisPajak,
              jenisSetoran,
              objekPajak,
              ptkp,
              ter,
              jenisObjekPajak,
              pkp,
              tarifPph21PP68Tahun2009,
              tarifPph21PP149Tahun2000,
              negara,
              tahun,
              cabang,
              profilUser,
              daftarUser,
              setting: settingAkses,
            },
            kewajibanPerpajakan: {
              pphPasal25,
              pphPasal29,
              pphFinal,
              pphPasal4Ayat2,
              pphPasal15,
              pphPasal19,
              pphPasal21,
              pphPasal23,
              pphPasal26,
            },
            cabangId: kodeCabang,
            _id: user.id,
            token: user.token,
          });
          setLoading(false);

          if (user.id == id) {
            dispatch({ type: "LOGOUT" });
            navigate("/");
          } else {
            navigate("/daftarUser");
          }
        }
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "14px",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Utility</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah User</h5>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Data Nama Sama`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Nama ${nama} sudah ada, ganti Nama!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Ok</Button>
        </DialogActions>
      </Dialog>
      <hr />
      <Card>
        <Card.Header>User</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateUser}>
            <Card>
              <Card.Header>Data Utama</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NPWP15 :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={npwp15} disabled />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NIK/NPWP16 :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nikNpwp16} disabled />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            NITKU :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control value={nitku} disabled />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={nama}
                              onChange={(e) =>
                                setNama(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Tipe User <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={tipeUser}
                              onChange={(e) => {
                                setTipeUser(e.target.value);
                              }}
                            >
                              {user.tipeUser === "OWNER"
                                ? tipeUserOptionOwner.map((tipeUser) => (
                                    <option value={tipeUser}>{tipeUser}</option>
                                  ))
                                : tipeUserOption.map((tipeUser) => (
                                    <option value={tipeUser}>{tipeUser}</option>
                                  ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          {user.tipeUser === "OWNER" ? (
                            <Form.Label column sm="4" style={textRight}>
                              Cabang <b style={colorRed}>*</b> :
                            </Form.Label>
                          ) : (
                            <Form.Label column sm="4" style={textRight}>
                              Cabang :
                            </Form.Label>
                          )}
                          <Col sm="8">
                            {user.tipeUser === "OWNER" ? (
                              <Form.Select
                                required
                                value={kodeCabang}
                                onChange={(e) => {
                                  setKodeCabang(e.target.value);
                                }}
                              >
                                {cabangs.map((cabang, index) => (
                                  <option value={cabang.id}>
                                    {cabang.id} - {cabang.namaCabang}
                                  </option>
                                ))}
                              </Form.Select>
                            ) : (
                              <Form.Control
                                required
                                value={kodeCabang}
                                disabled
                                readOnly
                              />
                            )}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Password :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={password}
                              onChange={(e) =>
                                setPassword(e.target.value.toUpperCase())
                              }
                              placeholder="Isi jika ingin mengganti Password!"
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Passphrase :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              value={passphrase}
                              onChange={(e) =>
                                setPassphrase(e.target.value.toUpperCase())
                              }
                              placeholder="Isi jika ingin mengganti Passphrase!"
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Data Lainnya</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={alamat}
                              onChange={(e) =>
                                setAlamat(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Telepon <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={nomorTelepon}
                              onChange={(e) =>
                                setNomorTelepon(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Email <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}></Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Data KLU</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            KEL KLU <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Autocomplete
                              size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={kelompokKegiatanEkonomiKluOptions}
                              renderInput={(params) => (
                                <TextField
                                  error={
                                    error &&
                                    kodeKelompokKegiatanEkonomiKlu.length ===
                                      0 &&
                                    true
                                  }
                                  size="small"
                                  {...params}
                                />
                              )}
                              onInputChange={(e, value) => {
                                setKodeKelompokKegiatanEkonomiKlu(
                                  value.split(" ", 1)[0]
                                );
                              }}
                              value={kodeKelompokKegiatanEkonomiKlu}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}></Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Info Perpajakan</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            KPP Administrasi <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kppAdministrasi}
                              onChange={(e) =>
                                setKppAdministrasi(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            No. Telepon KPP <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={noTeleponKpp}
                              onChange={(e) =>
                                setNoTeleponKpp(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Account Representative <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={accountRepresentative}
                              onChange={(e) =>
                                setAccountRepresentative(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Status Wajib Pajak
                                      </p>
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>AKTIF</li>
                                        <p>(Jika User ini Aktif Wajib Pajak)</p>
                                        <li>TIDAK AKTIF</li>
                                        <p>
                                          (Jika User ini Tidak Aktif Wajib
                                          Pajak)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Status Wp <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={statusWp}
                              onChange={(e) => {
                                setStatusWp(e.target.value);
                              }}
                            >
                              {statusWpOption.map((statusWp) => (
                                <option value={statusWp}>{statusWp}</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRightInfo}>
                            <div style={infoContainer}>
                              <InfoIcon
                                fontSize="small"
                                style={infoStyle}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOpenInfoFungsi(true);
                                  setInfoFungsi(
                                    <div>
                                      <p>
                                        Untuk mengaktifkan Status Pengusaha Kena
                                        Pajak
                                      </p>
                                      <p>Pilihan:</p>
                                      <ul>
                                        <li>NON PKP</li>
                                        <p>
                                          (Jika User ini Bukan Pengusaha Kena
                                          Pajak)
                                        </p>
                                        <li>PKP</li>
                                        <p>
                                          (Jika User ini Pengusaha Kena Pajak)
                                        </p>
                                      </ul>
                                    </div>
                                  );
                                }}
                              />
                            </div>
                            Status Pkp <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Select
                              required
                              value={statusPkp}
                              onChange={(e) => {
                                setStatusPkp(e.target.value);
                              }}
                            >
                              {statusPkpOption.map((statusPkp) => (
                                <option value={statusPkp}>{statusPkp}</option>
                              ))}
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Identitas Penanggung Jawab</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama Penanggung Jawab <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={namaPenanggungJawab}
                              onChange={(e) =>
                                setNamaPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nik Penanggung Jawab <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={nikPenanggungJawab}
                              onChange={(e) =>
                                setNikPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Npwp Penanggung Jawab <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={npwpPenanggungJawab}
                              onChange={(e) =>
                                setNpwpPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Jabatan Penanggung Jawab <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={jabatanPenanggungJawab}
                              onChange={(e) =>
                                setJabatanPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Kebangsaan Penanggung Jawab{" "}
                            <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={kebangsaanPenanggungJawab}
                              onChange={(e) =>
                                setKebangsaanPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Alamat Pj Badan Penanggung Jawab{" "}
                            <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={alamatPjBadanPenanggungJawab}
                              onChange={(e) =>
                                setAlamatPjBadanPenanggungJawab(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Dokumen Pendirian</Card.Header>
              <Card.Body>
                <Box sx={textFieldContainer}>
                  <Box sx={textFieldWrapper}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Akta <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={nomorAkta}
                              onChange={(e) =>
                                setNomorAkta(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Tempat Akta <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={tempatAkta}
                              onChange={(e) =>
                                setTempatAkta(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                  <Box sx={[textFieldWrapper, secondWrapper]}>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nama Notaris <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={namaNotaris}
                              onChange={(e) =>
                                setNamaNotaris(e.target.value.toUpperCase())
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Form.Label column sm="4" style={textRight}>
                            Nomor Akta Perubahan <b style={colorRed}>*</b> :
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              required
                              value={nomorAktaPerubahan}
                              onChange={(e) =>
                                setNomorAktaPerubahan(
                                  e.target.value.toUpperCase()
                                )
                              }
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Hak Akses User</Card.Header>
              <Card.Body>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <Form>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={checkAll} />}
                          label="Pilih Semua"
                          onChange={() => {
                            setCheckAll(!checkAll);
                            // Akses Master
                            setKategoriKlu(!checkAll);
                            setGolonganPokokKlu(!checkAll);
                            setGolonganKlu(!checkAll);
                            setSubGolonganKlu(!checkAll);
                            setKelompokKegiatanEkonomiKlu(!checkAll);
                            setJenisPajak(!checkAll);
                            setJenisSetoran(!checkAll);
                            setObjekPajak(!checkAll);
                            setPtkp(!checkAll);
                            setTer(!checkAll);
                            setJenisObjekPajak(!checkAll);
                            setPkp(!checkAll);
                            setTarifPph21PP68Tahun2009(!checkAll);
                            setTarifPph21PP149Tahun2000(!checkAll);
                            setNegara(!checkAll);
                            setTahun(!checkAll);
                            setCabang(!checkAll);
                            // Akses Utility
                            setProfilUser(!checkAll);
                            setDaftarUser(!checkAll);
                            setSettingAkses(!checkAll);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Box>
                </Box>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <Typography variant="p" sx={[spacingTop]}>
                      Master
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={kategoriKlu} />}
                        label="Kategori KLU"
                        onChange={() => setKategoriKlu(!kategoriKlu)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={golonganPokokKlu} />}
                        label="Golongan Pokok KLU"
                        onChange={() => setGolonganPokokKlu(!golonganPokokKlu)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={golonganKlu} />}
                        label="Golongan KLU"
                        onChange={() => setGolonganKlu(!golonganKlu)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={subGolonganKlu} />}
                        label="Sub Golongan KLU"
                        onChange={() => setSubGolonganKlu(!subGolonganKlu)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox checked={kelompokKegiatanEkonomiKlu} />
                        }
                        label="Kelompok Kegiatan Ekonomi KLU"
                        onChange={() =>
                          setKelompokKegiatanEkonomiKlu(
                            !kelompokKegiatanEkonomiKlu
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={jenisPajak} />}
                        label="Jenis Pajak"
                        onChange={() => setJenisPajak(!jenisPajak)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={jenisSetoran} />}
                        label="Jenis Setoran"
                        onChange={() => setJenisSetoran(!jenisSetoran)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={objekPajak} />}
                        label="Objek Pajak"
                        onChange={() => setObjekPajak(!objekPajak)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={ptkp} />}
                        label="PTKP"
                        onChange={() => setPtkp(!ptkp)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={ter} />}
                        label="TER"
                        onChange={() => setTer(!ter)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={jenisObjekPajak} />}
                        label="Jenis Objek Pajak"
                        onChange={() => setJenisObjekPajak(!jenisObjekPajak)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pkp} />}
                        label="PKP"
                        onChange={() => setPkp(!pkp)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={tarifPph21PP68Tahun2009} />}
                        label="Tarif Pph 21 PP 68 Tahun 2009"
                        onChange={() =>
                          setTarifPph21PP68Tahun2009(!tarifPph21PP68Tahun2009)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox checked={tarifPph21PP149Tahun2000} />
                        }
                        label="Tarif Pph 21 PP 149 Tahun 2000"
                        onChange={() =>
                          setTarifPph21PP149Tahun2000(!tarifPph21PP149Tahun2000)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={negara} />}
                        label="Negara"
                        onChange={() => setNegara(!negara)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={tahun} />}
                        label="Tahun"
                        onChange={() => setTahun(!tahun)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={cabang} />}
                        label="Cabang"
                        onChange={() => setCabang(!cabang)}
                      />
                    </FormGroup>
                  </Box>
                  <Box sx={[showDataWrapper, secondWrapper]}>
                    <Typography variant="p" sx={[spacingTop]}>
                      Utility
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={profilUser} />}
                        label="Profil User"
                        onChange={() => setProfilUser(!profilUser)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={daftarUser} />}
                        label="Daftar User"
                        onChange={() => setDaftarUser(!daftarUser)}
                      />
                    </FormGroup>
                    {user.tipeUser === "OWNER" && (
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={settingAkses} />}
                          label="Setting"
                          onChange={() => setSettingAkses(!settingAkses)}
                        />
                      </FormGroup>
                    )}
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Card style={{ marginTop: "20px" }}>
              <Card.Header>Akses Kewajiban Perpajakan</Card.Header>
              <Card.Body>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <Form>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={checkAllKewajiban} />}
                          label="Pilih Semua"
                          onChange={() => {
                            setCheckAllKewajiban(!checkAllKewajiban);
                            // Akses Kewajiban Perpajakan
                            setPphPasal25(!checkAllKewajiban);
                            setPphPasal29(!checkAllKewajiban);
                            setPphFinal(!checkAllKewajiban);
                            setPphPasal4Ayat2(!checkAllKewajiban);
                            setPphPasal15(!checkAllKewajiban);
                            setPphPasal19(!checkAllKewajiban);
                            setPphPasal21(!checkAllKewajiban);
                            setPphPasal23(!checkAllKewajiban);
                            setPphPasal26(!checkAllKewajiban);
                          }}
                        />
                      </FormGroup>
                    </Form>
                  </Box>
                </Box>
                <Box sx={showDataContainer}>
                  <Box sx={showDataWrapper}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal25} />}
                        label="PPh Pasal 25"
                        onChange={() => setPphPasal25(!pphPasal25)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal29} />}
                        label="PPh Pasal 29"
                        onChange={() => setPphPasal29(!pphPasal29)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphFinal} />}
                        label="PPh Final"
                        onChange={() => setPphFinal(!pphFinal)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal4Ayat2} />}
                        label="PPh Pasal 4 ayat 2"
                        onChange={() => setPphPasal4Ayat2(!pphPasal4Ayat2)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal15} />}
                        label="PPh Pasal 15"
                        onChange={() => setPphPasal15(!pphPasal15)}
                      />
                    </FormGroup>
                  </Box>
                  <Box sx={[showDataWrapper, secondWrapper]}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal19} />}
                        label="PPh Pasal 19"
                        onChange={() => setPphPasal19(!pphPasal19)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal21} />}
                        label="PPh Pasal 21"
                        onChange={() => setPphPasal21(!pphPasal21)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal23} />}
                        label="PPh Pasal 23"
                        onChange={() => setPphPasal23(!pphPasal23)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={pphPasal26} />}
                        label="PPh Pasal 26"
                        onChange={() => setPphPasal26(!pphPasal26)}
                      />
                    </FormGroup>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/daftarUser")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default UbahUser;

const showDataContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const checkboxTitle = {
  marginBottom: 0,
};

const secondCheckboxTitle = {
  marginTop: 15,
  marginBottom: 0,
};

const textFieldContainer = {
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const textFieldWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  maxWidth: {
    md: "40vw",
  },
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const colorRed = {
  color: "red",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
