import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import ScreenshotEBupot2126PerhitunganPPhPasal21 from "../../../assets/ScreenshotEBupot2126PerhitunganPPhPasal21.png";
import ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp from "../../../assets/ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp.png";
import ScreenshotEBupot2126TarifTer from "../../../assets/ScreenshotEBupot2126TarifTer.png";
import ScreenshotEBupot2126TarifPkp from "../../../assets/ScreenshotEBupot2126TarifPkp.png";
import ScreenshotEBupot2126TarifFormulasiPerhitungan from "../../../assets/ScreenshotEBupot2126TarifFormulasiPerhitungan.png";
import ScreenshotEBupot2126TarifBebasInput from "../../../assets/ScreenshotEBupot2126TarifBebasInput.png";
import { Colors } from "../../../constants/styles";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const TambahJenisObjekPajak = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeObjekPajak, setKodeObjekPajak] = useState("");
  const [kodeJenisObjekPajak, setKodeJenisObjekPajak] = useState("");
  const [namaJenisObjekPajak, setNamaJenisObjekPajak] = useState("");

  let bupot2126SkemaPenghitunganOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126SkemaPenghitungan, setBupot2126SkemaPenghitungan] =
    useState("");

  let bupot2126PtkpTahunanOptions = [{ label: "true" }, { label: "false" }];
  const [bupot2126PtkpTahunan, setBupot2126PtkpTahunan] = useState("");

  let bupot2126DasarPengenaanPajakOptions = [
    { label: "true" },
    { label: "false" },
  ];
  const [bupot2126DasarPengenaanPajak, setBupot2126DasarPengenaanPajak] =
    useState("");

  let tarifBupot2126Options = [
    { label: "TER" },
    { label: "PKP" },
    { label: "Formulasi Penghitungan" },
    { label: "Bebas Input" },
  ];
  const [tarifBupot2126, setTarifBupot2126] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [objekPajaks, setObjekPajaks] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let objekPajakOptions = objekPajaks.map((objekPajak) => ({
    label: `${objekPajak.kodeObjekPajak} - ${objekPajak.namaObjekPajak}`,
  }));

  useEffect(() => {
    getObjekPajakData();
  }, []);

  const getObjekPajakData = async () => {
    const response = await axios.post(`${tempUrl}/objekPajaks`, {
      _id: user.id,
      token: user.token,
    });
    setObjekPajaks(response.data);
  };

  const saveJenisObjekPajak = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeObjekPajak.length !== 0 &&
      bupot2126SkemaPenghitungan.length !== 0 &&
      bupot2126PtkpTahunan.length !== 0 &&
      bupot2126DasarPengenaanPajak.length !== 0 &&
      tarifBupot2126.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveJenisObjekPajak`, {
          kodeObjekPajak,
          kodeJenisObjekPajak,
          namaJenisObjekPajak,
          bupot2126SkemaPenghitungan,
          bupot2126PtkpTahunan,
          bupot2126DasarPengenaanPajak,
          tarifBupot2126,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/jenisObjekPajak");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Jenis Objek Pajak</h5>
      <hr />
      <Card>
        <Card.Header>Jenis Objek Pajak</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveJenisObjekPajak}>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode Objek Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={objekPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeObjekPajak.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeObjekPajak(value.split(" ", 1)[0]);
                        setKodeJenisObjekPajak(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Kode <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      required
                      value={kodeJenisObjekPajak}
                      onChange={(e) => setKodeJenisObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      required
                      value={namaJenisObjekPajak}
                      onChange={(e) => setNamaJenisObjekPajak(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Skema Perhitungan di E-Bupot
                                21/26
                              </p>
                              <img
                                src={ScreenshotEBupot2126PerhitunganPPhPasal21}
                                alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Skema
                                  Perhitungan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Skema Perhitungan)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Skema Perhitungan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={bupot2126SkemaPenghitunganOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error &&
                            bupot2126SkemaPenghitungan.length === 0 &&
                            true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setBupot2126SkemaPenghitungan(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Ptkp Tahunan di E-Bupot 21/26
                              </p>
                              <img
                                src={ScreenshotEBupot2126PerhitunganPPhPasal21}
                                alt="ScreenshotEBupot2126PerhitunganPPhPasal21"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Ptkp
                                  Tahunan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Ptkp Tahunan)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Ptkp Tahunan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={bupot2126PtkpTahunanOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error && bupot2126PtkpTahunan.length === 0 && true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setBupot2126PtkpTahunan(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>
                                Untuk mengaktifkan Dasar Pengenaan Pajak di
                                E-Bupot 21/26
                              </p>
                              <img
                                src={
                                  ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp
                                }
                                alt="ScreenshotEBupot2126PPhPasal21JenisObjekPajakDpp"
                                style={{ width: "550px" }}
                              />
                              <p>Pilihan:</p>
                              <ul>
                                <li>true</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 ada Ptkp
                                  Tahunan)
                                </p>
                                <li>false</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26 tidak ada
                                  Dasar Pengenaan Pajak)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Dasar Pengenaan Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={bupot2126DasarPengenaanPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={
                            error &&
                            bupot2126DasarPengenaanPajak.length === 0 &&
                            true
                          }
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setBupot2126DasarPengenaanPajak(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="6" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk Tarif E-Bupot 21/26</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotEBupot2126TarifTer}
                                  alt="ScreenshotEBupot2126TarifTer"
                                  style={{ width: "500px" }}
                                />
                                <li>TER</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif TER)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotEBupot2126TarifPkp}
                                  alt="ScreenshotEBupot2126TarifPkp"
                                  style={{ width: "500px" }}
                                />
                                <li>PKP</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif PKP)
                                </p>
                                <hr />
                                <img
                                  src={
                                    ScreenshotEBupot2126TarifFormulasiPerhitungan
                                  }
                                  alt="ScreenshotEBupot2126TarifFormulasiPerhitungan"
                                  style={{ width: "500px" }}
                                />
                                <li>Formulasi Penghitungan</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif Formulasi Penghitungan)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotEBupot2126TarifBebasInput}
                                  alt="ScreenshotEBupot2126TarifBebasInput"
                                  style={{ width: "500px" }}
                                />
                                <li>Bebas Input</li>
                                <p>
                                  (Jika Kode Objek Pajak E-Bupot 21/26
                                  menggunakan tarif Bebas Input)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Tarif Bupot 21/26 <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="6">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={tarifBupot2126Options}
                      renderInput={(params) => (
                        <TextField
                          error={error && tarifBupot2126.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setTarifBupot2126(value);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/jenisObjekPajak")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TambahJenisObjekPajak;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
