import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import { Box, Button, Snackbar, Alert } from "@mui/material";
import { NumericFormat } from "react-number-format";
import EditIcon from "@mui/icons-material/Edit";

const UbahTarifPph21PP149Tahun2000 = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeTarifPph21PP149Tahun2000, setKodeTarifPph21PP149Tahun2000] =
    useState("");
  const [jumlahPenghasilanMin, setJumlahPenghasilanMin] = useState("");
  const [jumlahPenghasilanMax, setJumlahPenghasilanMax] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getTarifPph21PP149Tahun2000ById();
  }, []);

  const getTarifPph21PP149Tahun2000ById = async () => {
    setLoading(true);
    const response = await axios.post(
      `${tempUrl}/tarifPph21PP149Tahun2000s/${id}`,
      {
        _id: user.id,
        token: user.token,
      }
    );
    setKodeTarifPph21PP149Tahun2000(response.data.kodeTarifPph21PP149Tahun2000);
    setJumlahPenghasilanMin(response.data.jumlahPenghasilanMin);
    setJumlahPenghasilanMax(response.data.jumlahPenghasilanMax);
    setTarifPersen(response.data.tarifPersen);
    setLoading(false);
  };

  const updateTarifPph21PP149Tahun2000 = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      jumlahPenghasilanMin.length !== 0 &&
      jumlahPenghasilanMax.length !== 0 &&
      tarifPersen.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateTarifPph21PP149Tahun2000/${id}`, {
            jumlahPenghasilanMin,
            jumlahPenghasilanMax,
            tarifPersen,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          setLoading(false);
          navigate(`/tarifPph21PP149Tahun2000/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Tarif Pph 21 PP 149 Tahun 2000</h5>
      <hr />
      <Card>
        <Card.Header>Tarif Pph 21 PP 149 Tahun 2000</Card.Header>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={updateTarifPph21PP149Tahun2000}
          >
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama PTKP :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      value={kodeTarifPph21PP149Tahun2000}
                      disabled
                      readOnly
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Min <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMin}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMin(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Max <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMax}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMax(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tarif Persen (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={tarifPersen}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setTarifPersen(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/tarifPph21PP149Tahun2000")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahTarifPph21PP149Tahun2000;

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
