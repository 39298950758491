import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Alert,
  Button,
  Snackbar,
  Autocomplete,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const TambahSubGolonganKlu = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeGolonganKlu, setKodeGolonganKlu] = useState("");
  const [kodeSubGolonganKlu, setKodeSubGolonganKlu] = useState("");
  const [namaSubGolonganKlu, setNamaSubGolonganKlu] = useState("");
  const [ketSubGolonganKlu, setKetSubGolonganKlu] = useState("");

  const [golonganKlus, setGolonganKlus] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let golonganKluOptions = golonganKlus.map((golonganKlu) => ({
    label: `${golonganKlu.kodeGolonganKlu} - ${golonganKlu.namaGolonganKlu}`,
  }));

  useEffect(() => {
    getGolonganKluData();
  }, []);

  const getGolonganKluData = async () => {
    const response = await axios.post(`${tempUrl}/golonganKlus`, {
      _id: user.id,
      token: user.token,
    });
    setGolonganKlus(response.data);
  };

  const saveSubGolonganKlu = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity() && kodeGolonganKlu.length !== 0) {
      setLoading(true);
      try {
        setLoading(true);
        await axios.post(`${tempUrl}/saveSubGolonganKlu`, {
          kodeGolonganKlu,
          kodeSubGolonganKlu,
          namaSubGolonganKlu,
          ketSubGolonganKlu,
          userIdInput: user.id,
          _id: user.id,
          token: user.token,
          kodeCabang: user.cabang.id,
        });
        setLoading(false);
        navigate("/subGolonganKlu");
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  if (loading) {
    return <Loader />;
  }

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Tambah Sub Golongan KLU</h5>
      <hr />
      <Card>
        <Card.Header>Sub Golongan KLU</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={saveSubGolonganKlu}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Golongan KLU <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={golonganKluOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeGolonganKlu.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeGolonganKlu(value.split(" ", 1)[0]);
                        setKodeSubGolonganKlu(value.split(" ", 1)[0]);
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      value={kodeSubGolonganKlu}
                      onChange={(e) =>
                        setKodeSubGolonganKlu(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaSubGolonganKlu}
                      onChange={(e) =>
                        setNamaSubGolonganKlu(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketSubGolonganKlu}
                      onChange={(e) =>
                        setKetSubGolonganKlu(e.target.value.toUpperCase())
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box sx={spacingTop}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/subGolonganKlu")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                type="submit"
              >
                Simpan
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default TambahSubGolonganKlu;

const spacingTop = {
  mt: 4,
};

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
