import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Colors } from "../../../constants/styles";
import ScreenshotJenisSetoranMasaBulan1 from "../../../assets/ScreenshotJenisSetoranMasaBulan1.png";
import ScreenshotJenisSetoranMasaBulan2 from "../../../assets/ScreenshotJenisSetoranMasaBulan2.png";
import ScreenshotJenisSetoranMasaBulan3 from "../../../assets/ScreenshotJenisSetoranMasaBulan3.png";
import ScreenshotJenisSetoranNpwpLain0 from "../../../assets/ScreenshotJenisSetoranNpwpLain0.png";
import ScreenshotJenisSetoranNpwpLain1 from "../../../assets/ScreenshotJenisSetoranNpwpLain1.png";
import ScreenshotJenisSetoranButuhNop0 from "../../../assets/ScreenshotJenisSetoranButuhNop0.png";
import ScreenshotJenisSetoranButuhNop1 from "../../../assets/ScreenshotJenisSetoranButuhNop1.png";
import ScreenshotJenisSetoranButuhNosk0 from "../../../assets/ScreenshotJenisSetoranButuhNosk0.png";
import ScreenshotJenisSetoranButuhNosk1 from "../../../assets/ScreenshotJenisSetoranButuhNosk1.png";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const UbahJenisSetoran = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeJenisPajak, setKodeJenisPajak] = useState("");
  const [kodeJenisSetoran, setKodeJenisSetoran] = useState("");
  const [namaJenisSetoran, setNamaJenisSetoran] = useState("");
  const [ketJenisSetoran, setKetJenisSetoran] = useState("");

  // Kondisi Setoran
  const [masaBulan, setMasaBulan] = useState("");
  const [masaTahun, setMasaTahun] = useState("");
  const [mataUang, setMataUang] = useState("");
  const [wpBadan, setWpBadan] = useState("");
  const [wpPemungut, setWpPemungut] = useState("");
  const [wpOp, setWpOp] = useState("");
  const [npwpNol, setNpwpNol] = useState("");
  const [npwpLain, setNpwpLain] = useState("");
  const [butuhNop, setButuhNop] = useState("");
  const [butuhNosk, setButuhNosk] = useState("");

  const [openInfoFungsi, setOpenInfoFungsi] = useState(false);
  const [infoFungsi, setInfoFungsi] = useState("");

  const handleCloseInfoFungsi = () => {
    setOpenInfoFungsi(false);
  };

  const [jenisPajaks, setJenisPajaks] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let jenisPajakOptions = jenisPajaks.map((jenisPajak) => ({
    label: `${jenisPajak.kodeJenisPajak} - ${jenisPajak.namaJenisPajak}`,
  }));

  let masaBulanOptions = [
    {
      label: "1 - Input Masa Pajak Sampai Bulan Dikunci",
      id: 1,
    },
    {
      label: "2 - Hanya Input Tahun",
      id: 2,
    },
    {
      label: "3 - Input Masa Pajak Sampai Bulan Bisa Dipilih",
      id: 3,
    },
  ];

  let masaTahunOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let mataUangOptions = [
    {
      label: "1 - ",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let wpBadanOptions = [
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpPemungutOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let wpOpOptions = [
    {
      label: "0 - ",
      id: 0,
    },
    {
      label: "1 - ",
      id: 1,
    },
  ];

  let npwpNolOptions = [
    {
      label: "0 - Tidak tampil NPWP",
      id: 0,
    },
    {
      label: "1 - Tampil NPWP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let npwpLainOptions = [
    {
      label: "0 - Tidak muncul inputan pilihan Subjek Pajak",
      id: 0,
    },
    {
      label: "1 - Muncul inputan pilihan Subjek Pajak",
      id: 1,
    },
  ];

  let butuhNopOptions = [
    {
      label: "0 - Tidak muncul inputan NOP",
      id: 0,
    },
    {
      label: "1 - Muncul inputan NOP",
      id: 1,
    },
    {
      label: "2 - ",
      id: 2,
    },
  ];

  let butuhNoskOptions = [
    {
      label: "0 - Tidak muncul inputan Nomor Ketetapan",
      id: 0,
    },
    {
      label: "1 - Muncul inputan Nomor Ketetapan",
      id: 1,
    },
  ];

  useEffect(() => {
    getJenisPajakData();
    getJenisSetoranById();
  }, []);

  const getJenisPajakData = async () => {
    const response = await axios.post(`${tempUrl}/jenisPajaks`, {
      _id: user.id,
      token: user.token,
    });
    setJenisPajaks(response.data);
  };

  const getJenisSetoranById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/jenisSetorans/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setKodeJenisPajak(
      `${response.data.jenispajak.kodeJenisPajak} - ${response.data.jenispajak.namaJenisPajak}`
    );
    setKodeJenisSetoran(response.data.kodeJenisSetoran);
    setNamaJenisSetoran(response.data.namaJenisSetoran);
    setKetJenisSetoran(response.data.ketJenisSetoran);

    let tempMasaBulan = masaBulanOptions.find((data) => {
      return response.data.masaBulan == data.id;
    });
    setMasaBulan(tempMasaBulan.label);

    let tempMasaTahun = masaTahunOptions.find((data) => {
      return response.data.masaTahun == data.id;
    });
    setMasaTahun(tempMasaTahun.label);

    let tempMataUang = mataUangOptions.find((data) => {
      return response.data.mataUang == data.id;
    });
    setMataUang(tempMataUang.label);

    let tempWpBadan = wpBadanOptions.find((data) => {
      return response.data.wpBadan == data.id;
    });
    setWpBadan(tempWpBadan.label);

    let tempWpPemungut = wpPemungutOptions.find((data) => {
      return response.data.wpPemungut == data.id;
    });
    setWpPemungut(tempWpPemungut.label);

    let tempWpOp = wpOpOptions.find((data) => {
      return response.data.wpOp == data.id;
    });
    setWpOp(tempWpOp.label);

    let tempNpwpNol = npwpNolOptions.find((data) => {
      return response.data.npwpNol == data.id;
    });
    setNpwpNol(tempNpwpNol.label);

    let tempNpwpLain = npwpLainOptions.find((data) => {
      return response.data.npwpLain == data.id;
    });
    setNpwpLain(tempNpwpLain.label);

    let tempButuhNop = butuhNopOptions.find((data) => {
      return response.data.butuhNop == data.id;
    });
    setButuhNop(tempButuhNop.label);

    let tempButuhNosk = butuhNoskOptions.find((data) => {
      return response.data.butuhNosk == data.id;
    });
    setButuhNosk(tempButuhNosk.label);
    setLoading(false);
  };

  const updateJenisSetoran = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      kodeJenisPajak.length !== 0 &&
      masaBulan.length !== 0 &&
      masaTahun.length !== 0 &&
      mataUang.length !== 0 &&
      wpBadan.length !== 0 &&
      wpPemungut.length !== 0 &&
      wpOp.length !== 0 &&
      npwpNol.length !== 0 &&
      npwpLain.length !== 0 &&
      butuhNop.length !== 0 &&
      butuhNosk.length !== 0
    ) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateJenisSetoran/${id}`, {
            kodeJenisPajak: kodeJenisPajak.split(" ", 1)[0],
            kodeJenisSetoran,
            namaJenisSetoran,
            ketJenisSetoran,

            masaBulan: masaBulan.split(" ", 1)[0],
            masaTahun: masaTahun.split(" ", 1)[0],
            mataUang: mataUang.split(" ", 1)[0],
            wpBadan: wpBadan.split(" ", 1)[0],
            wpPemungut: wpPemungut.split(" ", 1)[0],
            wpOp: wpOp.split(" ", 1)[0],
            npwpNol: npwpNol.split(" ", 1)[0],
            npwpLain: npwpLain.split(" ", 1)[0],
            butuhNop: butuhNop.split(" ", 1)[0],
            butuhNosk: butuhNosk.split(" ", 1)[0],

            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          setLoading(false);
          navigate(`/jenisSetoran/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  const textRightInfo = {
    textAlign: screenSize >= 650 && "right",
    display: "flex",
    justifyContent: screenSize >= 650 && "end",
  };

  const inputWrapperDialogueSaved = {
    marginTop: screenSize >= 1000 && "20px",
    color: Colors.grey700,
    display: screenSize >= 600 && "flex",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Jenis Setoran</h5>
      <hr />
      <Form noValidate validated={validated} onSubmit={updateJenisSetoran}>
        <Card>
          <Card.Header>Jenis Setoran</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode Jenis Pajak <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={jenisPajakOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kodeJenisPajak.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKodeJenisPajak(value);
                      }}
                      value={kodeJenisPajak}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeJenisSetoran} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={namaJenisSetoran}
                      onChange={(e) => setNamaJenisSetoran(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Keterangan :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={ketJenisSetoran}
                      onChange={(e) => setKetJenisSetoran(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card style={spacingTop}>
          <Card.Header>Kondisi Setoran</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk mengaktifkan Status Wajib Pajak</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotJenisSetoranMasaBulan1}
                                  alt="ScreenshotJenisSetoranMasaBulan1"
                                  style={{ width: "500px" }}
                                />
                                <li>
                                  1 - Input Masa Pajak Sampai Bulan Dikunci
                                </li>
                                <p>
                                  (Jika di EBilling Masa Pajak sampai dengan
                                  dikunci)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotJenisSetoranMasaBulan2}
                                  alt="ScreenshotJenisSetoranMasaBulan2"
                                  style={{ width: "500px" }}
                                />
                                <li>2 - Hanya Input Tahun</li>
                                <p>
                                  (Jika di EBilling hanya Input Tahun Pajak)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotJenisSetoranMasaBulan3}
                                  alt="ScreenshotJenisSetoranMasaBulan3"
                                  style={{ width: "500px" }}
                                />
                                <li>
                                  3 - Input Masa Pajak Sampai Bulan Bisa Dipilih
                                </li>
                                <p>
                                  (Jika di EBilling Masa Pajak dari dan sampai
                                  dengan bisa dipilih)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Masa Bulan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={masaBulanOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && masaBulan.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMasaBulan(value);
                      }}
                      value={masaBulan}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Masa Tahun</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>1 -</li>
                                <p>(Jika Masa Tahun bisa dipilih)</p>
                                <li>2 - </li>
                                <p>(Jika Masa Tahun tidak bisa dipilih)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Masa Tahun <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={masaTahunOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && masaTahun.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMasaTahun(value);
                      }}
                      value={masaTahun}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Mata Uang</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>1 -</li>
                                <p>(Jika Mata Uang Rupiah)</p>
                                <li>2 - </li>
                                <p>(Jika Mata Uang selain Rupiah)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Mata Uang <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={mataUangOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && mataUang.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setMataUang(value);
                      }}
                      value={mataUang}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Wp Badan</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>1 -</li>
                                <p>(Jika Wp Badan)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Wp Badan <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpBadanOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpBadan.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpBadan(value);
                      }}
                      value={wpBadan}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Wp Pemungut</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>0 -</li>
                                <p>(Jika Bukan Wp Pemungut)</p>
                              </ul>
                              <ul>
                                <li>1 -</li>
                                <p>(Jika Wp Pemungut)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Wp Pemungut <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpPemungutOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpPemungut.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpPemungut(value);
                      }}
                      value={wpPemungut}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Wp Op</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>0 -</li>
                                <p>(Jika Bukan Wp Op)</p>
                              </ul>
                              <ul>
                                <li>1 -</li>
                                <p>(Jika Wp Op)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Wp Op <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={wpOpOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && wpOp.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setWpOp(value);
                      }}
                      value={wpOp}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Npwp Nol</p>
                              <p>Pilihan:</p>
                              <ul>
                                <li>0 - Tidak tampil NPWP</li>
                                <p>(Jika Tidak tampil NPWP)</p>
                              </ul>
                              <ul>
                                <li>1 - Tampil NPWP</li>
                                <p>(Jika Tampil NPWP)</p>
                              </ul>
                              <ul>
                                <li>2 -</li>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Npwp Nol <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={npwpNolOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && npwpNol.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setNpwpNol(value);
                      }}
                      value={npwpNol}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Npwp Lain</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotJenisSetoranNpwpLain0}
                                  alt="ScreenshotJenisSetoranNpwpLain0"
                                  style={{ width: "500px" }}
                                />
                                <li>
                                  0 - Tidak muncul inputan pilihan Subjek Pajak
                                </li>
                                <p>
                                  (Jika Tidak muncul inputan pilihan Subjek
                                  Pajak)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotJenisSetoranNpwpLain1}
                                  alt="ScreenshotJenisSetoranNpwpLain1"
                                  style={{ width: "500px" }}
                                />
                                <li>1 - Muncul inputan pilihan Subjek Pajak</li>
                                <p>
                                  (Jika Muncul inputan pilihan Subjek Pajak)
                                </p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Npwp Lain <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={npwpLainOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && npwpLain.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setNpwpLain(value);
                      }}
                      value={npwpLain}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk memilih Butuh Nop</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotJenisSetoranButuhNop0}
                                  alt="ScreenshotJenisSetoranButuhNop0"
                                  style={{ width: "500px" }}
                                />
                                <li>0 - Tidak muncul inputan NOP</li>
                                <p>(Jika Tidak muncul inputan NOP)</p>
                                <hr />
                                <img
                                  src={ScreenshotJenisSetoranButuhNop1}
                                  alt="ScreenshotJenisSetoranButuhNop1"
                                  style={{ width: "500px" }}
                                />
                                <li>1 - Muncul inputan NOP</li>
                                <p>(Jika Muncul inputan NOP)</p>
                                <hr />
                                <li>2 - </li>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Butuh Nop <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={butuhNopOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && butuhNop.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setButuhNop(value);
                      }}
                      value={butuhNop}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRightInfo}>
                    <div style={infoContainer}>
                      <InfoIcon
                        fontSize="small"
                        style={infoStyle}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setOpenInfoFungsi(true);
                          setInfoFungsi(
                            <div>
                              <p>Untuk mengaktifkan input No Sk</p>
                              <p>Pilihan:</p>
                              <ul>
                                <img
                                  src={ScreenshotJenisSetoranButuhNosk0}
                                  alt="ScreenshotJenisSetoranButuhNosk0"
                                  style={{ width: "500px" }}
                                />
                                <li>
                                  0 - Tidak muncul inputan Nomor Ketetapan
                                </li>
                                <p>
                                  (Jika Tidak muncul inputan Nomor Ketetapan)
                                </p>
                                <hr />
                                <img
                                  src={ScreenshotJenisSetoranButuhNosk1}
                                  alt="ScreenshotJenisSetoranButuhNosk1"
                                  style={{ width: "500px" }}
                                />
                                <li>1 - Muncul inputan Nomor Ketetapan</li>
                                <p>(Jika Muncul inputan Nomor Ketetapan)</p>
                              </ul>
                            </div>
                          );
                        }}
                      />
                    </div>
                    Butuh No Sk <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={butuhNoskOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && butuhNosk.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setButuhNosk(value);
                      }}
                      value={butuhNosk}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Box style={spacingTop}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/jenisSetoran")}
            sx={{ marginRight: 2 }}
          >
            {"< Kembali"}
          </Button>
          <Button variant="contained" startIcon={<EditIcon />} type="submit">
            Edit
          </Button>
        </Box>
      </Form>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
      <Dialog
        onClose={handleCloseInfoFungsi}
        aria-labelledby="customized-dialog-title"
        open={openInfoFungsi}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Info Fungsi
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInfoFungsi}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={inputWrapperDialogueSaved}>{infoFungsi}</div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default UbahJenisSetoran;

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};

const spacingTop = {
  marginTop: "20px",
};

const infoContainer = {
  display: "flex",
  alignItems: "center",
};

const infoStyle = {
  color: Colors.blue500,
};
