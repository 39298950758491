import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { Loader } from "../../../components";
import { Container, Card, Form, Row, Col } from "react-bootstrap";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Autocomplete,
  TextField,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import EditIcon from "@mui/icons-material/Edit";

const UbahTer = () => {
  const { screenSize } = useStateContext();
  const { user, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [kodeTer, setKodeTer] = useState("");
  const [namaPtkp, setNamaPtkp] = useState("");
  const [jumlahPenghasilanMin, setJumlahPenghasilanMin] = useState("");
  const [jumlahPenghasilanMax, setJumlahPenghasilanMax] = useState("");
  const [tarifPersen, setTarifPersen] = useState("");

  let kategoriOptions = [
    {
      label: "A",
    },
    {
      label: "B",
    },
    {
      label: "C",
    },
  ];
  const [kategori, setKategori] = useState("");

  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getTerById();
  }, []);

  const getTerById = async () => {
    setLoading(true);
    const response = await axios.post(`${tempUrl}/ters/${id}`, {
      _id: user.id,
      token: user.token,
    });
    setKodeTer(response.data.kodeTer);
    setNamaPtkp(response.data.ptkp.namaPtkp);
    setJumlahPenghasilanMin(response.data.jumlahPenghasilanMin);
    setJumlahPenghasilanMax(response.data.jumlahPenghasilanMax);
    setTarifPersen(response.data.tarifPersen);
    setKategori(response.data.kategori);
    setLoading(false);
  };

  const updateTer = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true);
      try {
        setLoading(true);
        try {
          setLoading(true);
          await axios.post(`${tempUrl}/updateTer/${id}`, {
            jumlahPenghasilanMin,
            jumlahPenghasilanMax,
            tarifPersen,
            kategori,
            userIdUpdate: user.id,
            _id: user.id,
            token: user.token,
            kodeCabang: user.cabang.id,
          });
          setLoading(false);
          navigate(`/ter/${id}`);
        } catch (error) {
          alert(error.response.data.message);
        }
        setLoading(false);
      } catch (error) {
        alert(error.response.data.message);
      }
      setLoading(false);
    } else {
      setError(true);
      setOpen(!open);
    }
    setValidated(true);
  };

  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <h3>Master</h3>
      <h5 style={{ fontWeight: 400 }}>Ubah Ter</h5>
      <hr />
      <Card>
        <Card.Header>Ter</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={updateTer}>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kode :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={kodeTer} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Nama PTKP :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control value={namaPtkp} disabled readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Min <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMin}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMin(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Jumlah Penghasilan Max <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={jumlahPenghasilanMax}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setJumlahPenghasilanMax(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Tarif Persen (%) <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <NumericFormat
                      required
                      value={tarifPersen}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      customInput={Form.Control}
                      onValueChange={(values) => {
                        setTarifPersen(
                          values.formattedValue.replace(/[\.,]/g, "")
                        );
                      }}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Label column sm="4" style={textRight}>
                    Kategori <b style={colorRed}>*</b> :
                  </Form.Label>
                  <Col sm="8">
                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={kategoriOptions}
                      renderInput={(params) => (
                        <TextField
                          error={error && kategori.length === 0 && true}
                          size="small"
                          {...params}
                        />
                      )}
                      onInputChange={(e, value) => {
                        setKategori(value);
                      }}
                      value={kategori}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/ter")}
                sx={{ marginRight: 2 }}
              >
                {"< Kembali"}
              </Button>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                type="submit"
              >
                Edit
              </Button>
            </Box>
          </Form>
        </Card.Body>
      </Card>
      {error && (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={alertBox}>
            Data belum terisi semua!
          </Alert>
        </Snackbar>
      )}
    </Container>
  );
};

export default UbahTer;

const alertBox = {
  width: "100%",
};

const colorRed = {
  color: "red",
};
